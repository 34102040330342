import React, {createContext, ReactNode, useEffect, useMemo, useState} from "react";
import {initReactI18next, useTranslation} from "react-i18next";
import {fetchLanguages, fetchTranslation} from "../service/appService";
import i18n from "i18next";
import {getFullLocation, getLocation} from "./appUtils";
import {equals, find, isEmpty, path, reduce} from "ramda";
import {SETTINGS} from "../settings";
import {Language} from "@encoway/c-services-js-client";
import {PROGRESS, useProgress} from "../hooks/useProgress";
import {Progress} from "../components/Progress";

export type Translation = { [p: string]: string } | undefined;

export interface Translations {
  [locale: string]: Translation
}

interface ShopProperties {
  legalNotice: string,
  privacyPolicy: string,
}

export interface AppState {
  url: string,
  uri: string,
  translations: Translations,
  shopProperties: ShopProperties,
  loaded: boolean,
}

i18n.use(initReactI18next).init({
  resources: {},
  fallbackLng: SETTINGS.FALLBACK_LANGUAGE.tag,
  interpolation: {
    escapeValue: false
  }
}).then();

const initialState = {
  url: SETTINGS.server.baseUrl,
  uri: SETTINGS.server.embedded.baseUrl,
  translations: {},
  languages: [SETTINGS.FALLBACK_LANGUAGE],
  loaded: false,
  shopProperties: {
    legalNotice: SETTINGS.shopProperties.legalNotice,
    privacyPolicy: SETTINGS.shopProperties.privacyPolicy,
    language: SETTINGS.FALLBACK_LANGUAGE.tag
  }
}

export const AppContext = createContext<AppState>(initialState);

export const AppProvider = AppContext.Provider;

function useApp(): AppState {
  const t = useTranslation();
  const {url, uri} = useMemo(() => ({url: getLocation(), uri: getFullLocation()}), []);
  const [translations, setTranslations] = useState<Translations>({});
  const {notLoaded, set, loaded} = useProgress();
  const shopProperties = useMemo(() => {
    const privacyPolicy = document.getElementById("oxsecurityinfo")?.getAttribute("href");
    const legalNotice = document.getElementById("oxagb")?.getAttribute("href");
    return {
      legalNotice: legalNotice || initialState.shopProperties.legalNotice,
      privacyPolicy: privacyPolicy || initialState.shopProperties.privacyPolicy,
    }
  }, []);

  useEffect(() => {
    if (isEmpty(translations)) {
      (async () => {
        const oxlang = document.querySelector("#oxlang")?.innerHTML;
        const data = await fetchLanguages();
        setTranslations(reduce((acc, ele) => ({...acc, [ele.tag]: undefined}), {}, data));
        const foundLng = find<Language>(language =>
          equals(language.tag.slice(0, 2), oxlang)
        )(data) || SETTINGS.FALLBACK_LANGUAGE;
        await i18n.changeLanguage(foundLng.tag);
      })();
    }
  }, [setTranslations, translations]);

  useEffect(() => {
    (async () => {
      const cacheTranslation = path([t.i18n.language], translations);
      if (cacheTranslation) {
        t.i18n.addResources(i18n.language, "t", cacheTranslation);
      } else {
        const fetchedTranslation = await fetchTranslation(i18n.language);
        t.i18n.addResources(i18n.language, "t", fetchedTranslation);
        setTranslations(prev => ({...prev, [t.i18n.language]: fetchedTranslation}));
        if (notLoaded) {
          set(PROGRESS.LOADED);
        }
      }
    })()
  }, [t.i18n.language]);

  return {url, uri, translations, shopProperties, loaded};
}

export function AppStore({children}: { children: ReactNode }) {
  const appStore = useApp();
  if (!appStore.loaded) {
    return <Progress/>
  }
  return <AppProvider value={appStore}>{children}</AppProvider>
}
