import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import {any, equals, keys, map} from "ramda";
import {AppContext} from "../../context/useApp";

export function LanguageChange() {
  const {i18n} = useTranslation();
  const {translations} = useContext(AppContext);

  if (any(val => equals(process.env.REACT_APP_ENVIRONMENT, val), ["dev", "test"])) {
    return <Box sx={{position: "absolute", padding: "1em .5em"}}>
      <Typography sx={{cursor: "pointer"}}>
        {map(locale =>
            <Typography
              key={locale}
              component="span"
              sx={{":not(:last-child):after": {content: '"|"', padding: "0 .2em"}}}
              onClick={() => i18n.changeLanguage(locale + "")}>
              {locale}
            </Typography>,
          keys(translations))}
      </Typography>
    </Box>
  }
  return null;
}