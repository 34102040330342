import {Button, Typography} from "@mui/material";
import {Modal} from "../../../../components/Modal";
import React, {useContext, useState} from "react";
import {PartButton} from "./PartButton";
import {useModal} from "../../../../hooks/useModal";
import {Trans, useTranslation} from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import {Flex} from "../../../../components/helpers/Flex";
import {PARTS_DOWNLOAD, PartsContext} from "../../../../context/useParts";
import {CheckBox} from "../../../../components/CheckBox";
import {AppContext} from "../../../../context/useApp";
import {useNavigate} from "react-router-dom";

export function DownloadParts() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {shopProperties: {legalNotice, privacyPolicy}} = useContext(AppContext);
  const {download} = useContext(PartsContext);
  const {open, close, isOpen} = useModal();
  const [agreement, setAgreement] = useState(false);

  function onDownload() {
    download(PARTS_DOWNLOAD.PDF)
      .then(id => navigate(`/parts/${id}`, {replace: true}));
    close();
  }

  return <>
    <PartButton icon={DownloadIcon} variant="contained" size="small" onClick={open}>
      {t("t:download_parts")}
    </PartButton>
    <Modal close={close} open={isOpen} title={<Typography component="span">{t("t:terms_website")}</Typography>}>
      <Typography variant="body2" component="div" sx={{p: "1em 0"}} onClick={() => setAgreement(!agreement)}>
        <CheckBox checked={agreement} onClick={() => setAgreement(!agreement)}/>
        <Trans
          i18nKey="t:agreement"
          values={{privacyPolicy: t("t:privacy_policy"), legalNotice: t("t:legal_notice")}}
          components={{
            href1: <a href={privacyPolicy} target="_blank" rel="noopener noreferrer"/>,
            href2: <a href={legalNotice} target="_blank" rel="noopener noreferrer"/>,
          }}
        />
      </Typography>
      <Flex justifyContent="flex-end" paddingTop="1em">
        <Button onClick={close} variant="contained">{t("t:cancel")}</Button>
        <Button onClick={onDownload} variant={!agreement ? "outlined" : "contained" } disabled={!agreement}>{t("t:download")}</Button>
      </Flex>
    </Modal>
  </>
}