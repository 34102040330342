import {PartButton} from "../PartButton";
import SaveIcon from '@mui/icons-material/Save';
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useModal} from "../../../../../hooks/useModal";
import {SETTINGS} from "../../../../../settings";
import {SaveModal} from "./modals/SaveModal";
import {ResponseModal} from "./modals/ResponseModal";
import {saveInShop} from "../../../../../service/partsService";
import {loggedIn} from "./saveConfigurationHelper";
import {PartsContext} from "../../../../../context/useParts";

export function SaveConfiguration() {
  const minLength = 1;
  const maxLength = 50;
  const {t} = useTranslation();
  const {open, close, isOpen} = useModal();
  const [value, setValue] = useState<string>('');
  const [isResponseModalOpen, setIsResponseModalOpen] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const {send, save} = useContext(PartsContext);

  const handleChange = (event: any) => {
    const newValue = event.target.value;

    newValue.length >= minLength ? setIsSaveDisabled(false) : setIsSaveDisabled(true);
    if (newValue.length <= maxLength) {
      setValue(event.target.value);
    }
  }

  const openResponseDialog = (isSuccess: boolean) => {
    close();
    setIsResponseModalOpen(true);
    setIsSuccessful(isSuccess);
  }

  const closeResponseDialog = () => {
    setIsResponseModalOpen(false);
  }

  const saveConfiguration = async () => {
    await save();
    const id: string = await send();
    saveInShop(id, value).then(response => {
      response.status === 200 ? openResponseDialog(true) : openResponseDialog(false);
    }, () => {
      openResponseDialog(false);
    });
    window.localStorage.setItem(SETTINGS.configuration.localStorageConfigurationName, value);
    close();
  }

  return <>
    <PartButton icon={SaveIcon} variant="contained" size="small" onClick={open}>
      {t("t:configuration_save")}
    </PartButton>
    <SaveModal isOpen={isOpen} value={value} loggedIn={loggedIn()} handleChange={handleChange} save={saveConfiguration} close={close}
               saveButtonDisabled={isSaveDisabled}/>
    <ResponseModal isOpen={isResponseModalOpen} success={isSuccessful} close={closeResponseDialog}
                   titleIcon={SaveOutlinedIcon}/>
  </>
}