import {CatalogService, Characteristic, CharacteristicValue, Http, Product} from "@encoway/c-services-js-client";
import React, {createContext, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {SETTINGS} from "../settings";
import {useTranslation} from "react-i18next";
import {map, path, pathOr, reduce, uniq} from "ramda";
import {toExtendedCharacteristic} from "./productUtils";

const http = Http.Basic(SETTINGS.server.credentials.user, SETTINGS.server.credentials.password);

export interface ExtendedProduct extends Product {
  characteristicValues: ExtendedCharacteristic
}

export interface ExtendedCharacteristic {
  [characteristicId: string]: CharacteristicValue & Characteristic
}

export interface Products {
  [id: string]: ExtendedProduct
}

export interface ProductsState {
  [lang: string]: Products
}

export interface MainProduct {
  name: string,
  text: string,
  image: string,
}

export interface ProductState extends MainProduct {
  get(id: string | string[]): Promise<Products>
}

export const ProductContext = createContext<ProductState>({
  name: "",
  text: "",
  image: "",
  get: async function () {
    throw new Error("get not initialized");
  }
});
export const ProductProvider = ProductContext.Provider;

function useProduct(): ProductState {
  const {i18n} = useTranslation();
  const catalogService = useMemo(() =>
      new CatalogService(http, SETTINGS.server.baseUrl, i18n.language),
    [i18n.language]);
  const [mainProduct, setMainProduct] = useState<MainProduct>({name: "", text: "", image: "",});
  const [products, setProducts] = useState<ProductsState>({});

  const fetchProduct = useCallback(async function (id: string) {
    const {product, characteristics} = await catalogService.product(id);
    const characteristicValues = reduce(toExtendedCharacteristic(product.characteristicValues), {}, characteristics);
    return {[id]: {...product, characteristicValues}}
  }, [catalogService]);

  const getOne = useCallback(async function (id: string): Promise<Products> {
    const productState = path<ExtendedProduct>([i18n.language, id], products);
    if (productState) {
      return {[id]: productState};
    }
    return fetchProduct(id);
  }, [products, i18n.language, catalogService]);

  const multiGet = useCallback(async function (ids: string[]): Promise<Products> {
    async function fromStateOrFetch(id: string): Promise<Products> {
      const cachedProduct = path<ExtendedProduct>([i18n.language, id], products);
      if (cachedProduct) {
        return {[id]: products[i18n.language][id]};
      }
      return fetchProduct(id);
    }

    const newProducts = await Promise.all(map(fromStateOrFetch, uniq(ids)));
    return reduce((acc, ele) => ({...acc, ...ele}), {}, newProducts);
  }, [products, i18n.language, catalogService]);

  const get = useCallback(async function (id: string | string[]): Promise<Products> {
    const newProduct = Array.isArray(id) ? await multiGet(id) : await getOne(id);
    setProducts(prev => ({...prev, [i18n.language]: {...prev[i18n.language], ...newProduct}}));
    return newProduct;
  }, [i18n.language, setProducts, getOne, multiGet])

  useEffect(() => {
    get(SETTINGS.requirements.articleName)
      .then(p => setMainProduct({
        name: pathOr("", ["name"], p[SETTINGS.requirements.articleName]),
        text: pathOr("", ["characteristicValues", 'long_text_gui', 'values', '0'], p[SETTINGS.requirements.articleName]),
        image: pathOr("", ["characteristicValues", 'Product_image', 'values', '0', 'uri'], p[SETTINGS.requirements.articleName]),
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return {
    ...mainProduct,
    get,
  }
}

export function ProductStore({children}: { children: ReactNode }) {
  return <ProductProvider value={useProduct()}>
    {children}
  </ProductProvider>
}
