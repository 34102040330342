import {Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {PartsContext} from "../../context/useParts";
import {isEmpty} from "ramda";
import {PartsSidebar} from "./partsSidebar/PartsSidebar";
import {PartsTable} from "./partsTable/PartsTable";
import {Progress} from "../../components/Progress";
import React, {useContext, useEffect, useMemo} from "react";
import {PROGRESS, useProgress} from "../../hooks/useProgress";

export function Parts() {
  const {parts, reload} = useContext(PartsContext);
  const {t} = useTranslation();
  const {id} = useParams();
  const {loaded, notLoaded, set} = useProgress();
  const emptyParts = useMemo(() => isEmpty(parts), [parts]);

  useEffect(() => {
    if (id && notLoaded) {
      set(PROGRESS.LOADING);
      reload(id).then(() => set(PROGRESS.LOADED));
    } else if (!id && notLoaded) {
      set(PROGRESS.LOADED);
    }
  }, [id, emptyParts, set, reload, notLoaded]);

  if (emptyParts && !loaded) {
    return <Progress/>;
  }

  return <>
    <Typography variant="h1" component="h1">
      {t("t:your_cart")}
    </Typography>
    <Grid spacing={4} container>
      <Grid sm={12} lg={9} item>
        <PartsTable items={parts}/>
      </Grid>
      <Grid sm={12} lg={3} item>
        <PartsSidebar/>
      </Grid>
    </Grid>
  </>
}