import {fetchGet, fetchPost} from "./axiosWrapper";
import {MailPostData} from "../context/useMail";
import axios, {AxiosPromise} from "axios";
import {DownloadPartsPDFData} from "../types/parts";
import {getLocation} from "../context/appUtils";

const shopHost = getLocation();

export function sendToCart(queryParams: string): AxiosPromise<void> {
  return axios.get(`${shopHost}/index.php?cl=apptobasket${queryParams}`);
}

export function resetCart(): AxiosPromise<void> {
  return axios.get(`${shopHost}/index.php?cl=apptobasket&clearbasket=1`);
}

export function sendEmail(data: MailPostData, lang: string): AxiosPromise<void> {
  return fetchPost<void, MailPostData>("email/send")({data}, {"Accept-Language": lang});
}

export function saveInShop(id: string | undefined, name: string): AxiosPromise<void> {
  return axios.get(`${shopHost}/index.php?cl=wd_account_configurator_config&fnc=create&type=variox&id=${id}&name=${name}`);
}

export async function downloadPDF(data: DownloadPartsPDFData, lang: string): Promise<Blob> {
  const {data: {content}} = await fetchPost<any, DownloadPartsPDFData>("document/download/")
  ({data}, {"Accept-Language": lang});
  const base64toBlob = await fetch(`data:application/octet-stream;base64,${content}`);
  return base64toBlob.blob();
}

export async function fetchAssemblyPosition(lang: string): Promise<any> {
  return fetchGet<any>(`api/catalog/products/murr_montageposition/`)({}, {"Accept-Language": lang});
}