import {Button, Typography} from "@mui/material";
import {Modal} from "../../../../components/Modal";
import React, {useContext, useState} from "react";
import {PartButton} from "./PartButton";
import {useModal} from "../../../../hooks/useModal";
import {Trans, useTranslation} from "react-i18next";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {Flex} from "../../../../components/helpers/Flex";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {PartsContext} from "../../../../context/useParts";
import {useNavigate} from "react-router-dom";

function TitleIcon({added}: { added: boolean }) {
  const {t} = useTranslation();
  if (added) {
    return <>
      <CheckCircleOutlineOutlinedIcon style={{color: "green", paddingRight: ".3em", width: "32px"}}/>
      <Typography component="span" sx={{color: "green"}}>
        <Trans
          i18nKey="t:add_to_cart_title_success" // optional -> fallbacks to defaults if not provided
          values={{successful: t("t:add_to_cart_successful"), cart: t("t:add_to_cart_added")}}
          components={{strong: <strong/>}}
        />
      </Typography>
    </>
  }
  /*
    TODO: Siehe ME-246. Folgendes nach der Zertifizierung wieder einsetzen, anstelle des aktuellen:

   return <>
    <InfoOutlinedIcon style={{paddingRight: ".3em", width: "32px"}}/>
    <Typography component="span">{t("t:add_to_cart_title")}</Typography>
  </>
   */
  return <>
    <InfoOutlinedIcon style={{paddingRight: ".3em", width: "32px"}}/>
    <Typography component="span">{t("t:cannot_add_to_cart_title")}</Typography>
  </>
}

export function AddToCart() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {toCart} = useContext(PartsContext);
  const {open, close, isOpen} = useModal();
  const [added, setAdded] = useState<boolean>(false);

  function addToCart() {
    setAdded(true)
  }

  function reloadOrClose() {
    if (added) {
      toCart().then(id => {
        navigate(`/parts/${id}`, {replace: true})
        /* eslint-disable-next-line no-restricted-globals */
        location.reload();
      });
    }
    close();
  }

  /*
   * TODO: Siehe ME-246. Sobald das Zertifikat da ist und da Produkt freigegeben ist folgendes wieder einsetzen:

  return <>
    <PartButton icon={ShoppingCartIcon} variant="contained" size="small" onClick={open}>
      {t("t:add_to_cart")}
    </PartButton>
    <Modal close={reloadOrClose} open={isOpen} title={<TitleIcon added={added}/>}>
      {added ?
        <Flex justifyContent="flex-end" paddingTop="1em">
          <Button onClick={reloadOrClose} variant="contained" sx={{marginTop: "2em"}}>{t("t:okay")}</Button>
        </Flex>
        : <>
          <Typography variant="body2" component="p" sx={{p: "1em 0"}}>{t("t:add_to_cart_text")}</Typography>
          <Flex justifyContent="flex-end" paddingTop="1em">
            <Button onClick={close} variant="contained">{t("t:cancel")}</Button>
            <Button onClick={addToCart} variant="contained">{t("t:ok")}</Button>
          </Flex>
        </>
      }
    </Modal>
  </>
   */

  return <>
    <PartButton icon={ShoppingCartIcon} variant="contained" size="small" onClick={open}>
      {t("t:add_to_cart")}
    </PartButton>
    <Modal close={reloadOrClose} open={isOpen} title={<TitleIcon added={added}/>}>
      {added ?
          <Flex justifyContent="flex-end" paddingTop="1em">
            <Button onClick={reloadOrClose} variant="contained" sx={{marginTop: "2em"}}>{t("t:okay")}</Button>
          </Flex>
          : <>
            <Typography variant="body2" component="p" sx={{p: "1em 0"}}>{t("t:cannot_add_to_cart_text")}</Typography>
            <Flex justifyContent="flex-end" paddingTop="1em">
              <Button onClick={close} variant="contained">{t("t:ok")}</Button>
            </Flex>
          </>
      }
    </Modal>
  </>
}