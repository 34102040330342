import {Box, styled, Typography} from "@mui/material";
import React, {ReactNode} from "react";
import {InfoButton} from "../../../../components/InfoIcon";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {Flex} from "../../../../components/helpers/Flex";
import {useModal} from "../../../../hooks/useModal";
import {DropdownModal} from "../../../../components/DropdownModal";
import {useTranslation} from "react-i18next";
import {map, split} from "ramda";


const StyledBox = styled(Box)(({theme}) => ({
  background: `linear-gradient(180deg, ${theme.palette.info.light} 0%, ${theme.palette.info.main} 100%)`,
  border: "1px solid rgb(224, 224, 224)",
  padding: "1.8rem"
}));

const StyledHeadline = styled(Typography)(({theme}) => ({
  color: theme?.palette.contrast.dark,
  textTransform: "uppercase"
}));

type CadDownloadBoxProps = {
  headline: string,
  children?: ReactNode
}

export function DownloadBox({headline, children}: CadDownloadBoxProps) {
  const {isOpen, open, close, ref} = useModal();
  const {t} = useTranslation();

  return <StyledBox>
    <Flex alignItems="center" justifyContent="space-between">
      <StyledHeadline>
        {headline}
      </StyledHeadline>
      <InfoButton ref={ref} onClick={() => open()}>
        <InfoOutlinedIcon sx={{fontSize: "1.2rem"}}/>
      </InfoButton>
      <DropdownModal
        headline={t("t:download")}
        onClose={close}
        anchorEl={ref?.current}
        open={isOpen}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        transformOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        {map(item =>
                <Typography key={item} component="p" pt=".5em" variant="body1">{item}</Typography>,
            split("\n", t("t:download_text")))}
      </DropdownModal>
    </Flex>
    {children}
  </StyledBox>
}