import {useLocation, useNavigate} from "react-router-dom";
import {useContext, useMemo} from "react";
import {equals, startsWith} from "ramda";
import {ConfigurationContext} from "../../../context/useConfiguration";
import {PreConfigurationContext} from "../../../context/usePreConfiguration";

export const PATH = {
  INDEX: "/",
  CONFIGURATION: "/configuration/",
  PARTS: "/parts/",
  PRE: "/requirements/",
}

export interface TabStore {
  nav: {
    parts(): void,
    configuration(): void,
    requirements(): void,
  },
  selected: {
    configuration: boolean,
    parts: boolean,
    requirements: boolean,
  },
}

export function useTabs(): TabStore {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {createSnapshot} = useContext(ConfigurationContext);
  const {configurationId} = useContext(PreConfigurationContext);

  return useMemo(() => ({
    nav: {
      parts: async () => {
        await createSnapshot();
        navigate("/parts");
      },
      configuration: () => startsWith("/requirements", pathname)
        ? navigate(`/configuration/pre/${configurationId}`)
        : navigate("/configuration"),
      requirements: () => navigate("/"),
    },
    selected: {
      requirements: equals("/", pathname) || startsWith("/requirements", pathname),
      configuration: startsWith("/configuration", pathname),
      parts: startsWith("/parts", pathname)
    },
  }), [pathname, navigate, createSnapshot]);
}