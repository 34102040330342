import {any, equals, map, path} from "ramda";
import {MediaValue, NumberValue, ParameterTO} from "@encoway/c-services-js-client";
import {SelectedParameters} from "./usePreConfiguration";
import {ExtendedCharacteristic} from "./useProduct";

/**
 * Determines if any selection by the user is in the active parameterTO array
 * @param parameters the parameters to search with
 * @returns boolean true if any selection had taken place
 */
export function anySelectionByUser(parameters: ParameterTO[]): boolean {
  const selectionSources = map(param => path(["selectedValues", 0, "selectionSource"], param), parameters);
  return any(val => equals("SET_BY_USER", val), selectionSources);
}

/**
 * Function to determine all selected parameters to the given reducer
 * @param acc the selected parameters accumulator
 * @param parameter the parameter to reduce
 * @returns selectedParameters the selected parameters object
 */
export function toSelectedParameters(acc: SelectedParameters, parameter: ParameterTO): SelectedParameters {
  return {
    ...acc,
    [parameter.name]: path(["selectedValues", 0, "value"], parameter)
  }
}

interface ToPresetParameterProps {
  id: string,
  value: string | NumberValue | MediaValue
}

/**
 * Reducer Function to get all presets
 * @param characteristicsKeys the keys to use
 * @param characteristics the values of the characteristics to use
 * @returns
 */
export function toPresetParameter(characteristicsKeys: (string | number)[], characteristics: ExtendedCharacteristic) {
  return function (acc: ToPresetParameterProps[], {id, name}: ParameterTO): ToPresetParameterProps[] {
    const characteristic = characteristics[name];
    if (characteristic) {
      return [...acc, {id, value: characteristic.values[0]}];
    }
    return acc;
  }
}