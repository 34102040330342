import {useMemo, useState} from "react";
import {validateTypes, validateValue} from "./validateUtils";
import {all, equals, keys, reduce} from "ramda";
import {ValidatedValue, ValidatedValues, ValidateStore, ValidationOptions, ValidationValues} from "../types/validation";

export enum VALIDATION_TYPE {
  NONE,
  CHECKED,
  REQUIRED,
  REGEX_EMAIL,
  MIN_LENGTH,
  MAX_LENGTH,
}

export enum VALIDATION_STATUS {
  VALID,
  INVALID,
  NOT_VALIDATED
}

export function useValidate<T>(values: ValidationValues<T>, validationValues: ValidatedValues<T>, options: ValidationOptions): ValidateStore<T> {
  const [validated, setValidated] = useState<ValidatedValues<T>>(validationValues);
  const validateType = useMemo(() => validateTypes(options), [options]);

  function validate(key: keyof T, value: string | boolean): ValidatedValue {
    const initialTypes = validationValues[key].type
    const type = reduce(validateType(value), [], validationValues[key].type);
    const validatedEntry = validateValue(value, initialTypes, type);
    setValidated({...validated, [key]: validatedEntry});
    return validatedEntry
  }

  function validateEvery(): boolean {
    const entries = reduce((acc, key) => {
      const value = values[key];
      const initialTypes = validationValues[key].type
      const type = reduce(validateType(value), [], initialTypes);
      return {...acc, [key]: validateValue(value, initialTypes, type)}
    }, validated, keys(values));
    setValidated(entries);
    return all(key => equals(VALIDATION_STATUS.VALID, entries[key].status), keys(entries));
  }

  return {
    validated,
    validate,
    validateEvery
  }
}