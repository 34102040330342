import React, {useContext, useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import {PartsContext} from "../../context/useParts";
import {ConfigurationContext} from "../../context/useConfiguration";

export function LoadParts() {
  const {save, id} = useContext(PartsContext);
  const {edited} = useContext(ConfigurationContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (edited && id || !id) {
      save()
        .then(() => setLoading(false))
        .catch(() => setError(true));
    } else {
      setLoading(false);
    }
  }, []);

  if (error) {
    return <Navigate to="/configuration"/>
  }

  if (!loading) {
    return <Navigate to="/parts/new"/>
  }

  return null;
}