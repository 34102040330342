import {CircularProgress, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {ReactNode, useRef} from "react";
import {Flex, FlexCentered} from "../../../helpers/Flex";
import {useShareButton} from "./useShareButton";
import {CopyButton} from "./copyButton/CopyButton";
import {MailButton} from "./mailButton/MailButton";
import {ButtonRowButton} from "../ButtonRowButton";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import {DropdownModal} from "../../../DropdownModal";

interface SaveComponentProps {
  isOpen: boolean,
  children: ReactNode,

  onOpen(): void,

  onClose(): void
}

export function ShareComponent({isOpen, onClose, onOpen, children}: SaveComponentProps) {
  const {t} = useTranslation();
  const ref = useRef(null);

  return <Flex>
    <ButtonRowButton anchorRef={ref} text={t("t:share")} icon={<ShareOutlinedIcon/>} onClick={onOpen}/>
    <DropdownModal
      headline={t("t:share")}
      icon={<ShareOutlinedIcon/>}
      anchorEl={ref.current}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      transformOrigin={{vertical: "top", horizontal: "right"}}
      open={isOpen}
      onClose={onClose}>
      <Flex sx={{flexDirection: "column", width: 300}}>
        {children}
      </Flex>
    </DropdownModal>
  </Flex>
}

export function ShareButton() {
  const {t} = useTranslation();
  const {open, close, isOpen, error, link} = useShareButton();

  if (error) {
    return <ShareComponent isOpen={isOpen} onClose={close} onOpen={open}>
      <Typography component="p" variant="body2" sx={{flex: 1}}>{t("t:configuration_save_error")}</Typography>
    </ShareComponent>
  }

  return <ShareComponent isOpen={isOpen} onClose={close} onOpen={open}>
    {link
      ? <Typography component="p" variant="body2" sx={{flex: 1, lineBreak: "anywhere"}}>{link}</Typography>
      : <FlexCentered><CircularProgress size={24}/></FlexCentered>}
    <Flex sx={{alignSelf: "flex-end", paddingTop: "1.5em"}}>
      <CopyButton link={link} close={close}/>
      <MailButton link={link} close={close}/>
    </Flex>
  </ShareComponent>
}