import axios, {AxiosPromise, AxiosRequestConfig} from "axios";
import {SETTINGS} from "../settings";
import {replace, startsWith} from "ramda";

function replacePath(path: string): string {
  if (startsWith("/", path)) {
    return replace(/^\//, "", path)
  }
  return path;
}

export interface RequestData<R> extends AxiosRequestConfig {
  data: R
}

export interface RequestParameters<R> {
  params: R
}

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
type Payload<R> = RequestData<R> | RequestParameters<R> | {}

export function fetchData(method: HttpMethod) {
  return function <T = void, R = any>(path: string) {
    return function (payload?: Payload<R>, header?: object | null, emptyHost = false): AxiosPromise<T> {
      const host = emptyHost ? "" : `${SETTINGS.server.baseUrl}/`;
      return axios({
        method,
        url: `${host}${replacePath(path)}`,
        headers: {...header},
        ...(!header?.hasOwnProperty("Authorization") && {
          auth: {
            username: SETTINGS.server.credentials.user,
            password: SETTINGS.server.credentials.password
          }}),
        ...payload
      })
    }
  }
}

export const fetchGet = fetchData("GET");
export const fetchPost = fetchData("POST");
