import {ExtendedCharacteristic} from "./useProduct";
import {Characteristic, CharacteristicValue} from "@encoway/c-services-js-client";

export function toExtendedCharacteristic(characteristic: { [characteristicId: string]: CharacteristicValue }) {
  return function (acc: {}, ele: Characteristic): ExtendedCharacteristic {
    return {
      ...acc,
      [ele.id]: {
        ...characteristic[ele.id],
        ...ele,
      }
    }
  }
}