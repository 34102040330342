import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {ReactNode, useContext, useRef, useState} from "react";
import {Flex} from "../../../helpers/Flex";
import {ButtonRowButton} from "../ButtonRowButton";
import {DropdownModal} from "../../../DropdownModal";
import {QrCode2} from "@mui/icons-material";
import {Progress} from "../../../Progress";
import {useModal} from "../../../../hooks/useModal";
import {ARCodeContext} from "../../../../context/useARCode";
import {map, split} from "ramda";

interface SaveComponentProps {
  isOpen: boolean,
  children: ReactNode,

  onOpen(): void,

  onClose(): void
}

function ArComponent({isOpen, onClose, onOpen, children}: SaveComponentProps) {
  const {t} = useTranslation();
  const ref = useRef(null);


  return <Flex>
    <ButtonRowButton anchorRef={ref} text={t("t:ar_code")} icon={<QrCode2/>} onClick={onOpen}/>
    <DropdownModal
      headline={t("t:ar_code")}
      icon={<QrCode2/>}
      anchorEl={ref.current}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      transformOrigin={{vertical: "top", horizontal: "right"}}
      open={isOpen}
      onClose={onClose}>
      <Flex sx={{flexDirection: "column", width: 300}}>
        {children}
      </Flex>
    </DropdownModal>
  </Flex>
}

export function ArButton() {
  const {t} = useTranslation();
  const {open, close, isOpen} = useModal();
  const [error, setError] = useState<boolean>(false);
  const {code, generate} = useContext(ARCodeContext);

  function onOpen() {
    open();
    generate().catch((e: any) => {
      console.error(e);
      setError(true)
    });
  }

  if (error) {
    return <ArComponent isOpen={isOpen} onClose={close} onOpen={onOpen}>
      <Typography component="p" variant="body2" sx={{flex: 1}}>{t("t:ar_code_error")}</Typography>
    </ArComponent>
  }

  return <ArComponent isOpen={isOpen} onClose={close} onOpen={onOpen}>
    <Box>
      {map(item =>
          <Typography key={item} component="p" pt=".5em" variant="body2">{item}</Typography>,
        split("\n", t("t:ar_code_text")))}
    </Box>
    {code
      ? <Typography component="p" sx={{flex: 1, alignSelf: "center"}} variant="body2">
        <img src={code} alt="AR-Code"/>
      </Typography>
      : <Progress sx={{minHeight: "250px"}}/>}
  </ArComponent>
}