import {useTranslation} from "react-i18next";
import React, {ReactNode} from "react";
import {useModal} from "../../../../../hooks/useModal";
import {Button, Typography} from "@mui/material";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import {Modal} from "../../../../Modal";
import {MailForm} from "./MailForm";

function MailIcon({children}: { children?: ReactNode }) {
  return <>
    <EmailIcon style={{paddingRight: ".3em", width: "32px"}}/>
    {children}
  </>
}

export function MailButton({link, close}: { link: string, close: () => void }) {
  const {t} = useTranslation();
  const modal = useModal();

  function closeAll() {
    modal.close();
    close();
  }

  return <>
    <Button variant="contained" size="small" onClick={link ? modal.open : () => null}>
      <MailIcon>
        {t("t:email")}
      </MailIcon>
    </Button>
    <Modal close={modal.close} open={modal.isOpen} title={<MailIcon>{t("t:share_email_title")}</MailIcon>}>
        <Typography variant="body1" component="p" sx={{p: "1em 0"}}>{t("t:share_email_text")}</Typography>
        <MailForm saveClose={closeAll} close={modal.close}/>
    </Modal>
  </>
}