import {Box, Button, Modal as MuiModal, styled, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {ReactNode} from "react";

interface ModalProps {
  close: () => void,
  open: boolean,
  title?: ReactNode,
  children: ReactNode,
}

const StyledBox = styled(Box)(({theme}) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: "#FFF",
  border: "1px solid #E0E0E0",
  boxShadow: "0px 3px 6px #00000029",
  padding: "2em",
  minWidth: "min(400px, calc(40% + 100px))",
  maxWidth: "650px",
}));

export const StyledCloseButton = styled(Button)(({theme}) => ({
  background: "none",
  borderRadius: "50%",
  position: "absolute",
  top: ".625em",
  right: ".25em",
  minWidth: 0,
  fontSize: "24px",
  color: theme.palette.contrast.dark,
}));

export const StyledTypography = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  padding: "0 1.5em .5em 0"
}));

export function Modal({close, open, title, children}: ModalProps) {
  return <MuiModal
    id="encoway-modal"
    open={open}
    onClose={close}
  >
    <StyledBox>
      <StyledTypography variant="h2">
        {title}
      </StyledTypography>
      <StyledCloseButton onClick={close}>
        <CloseIcon/>
      </StyledCloseButton>
      {children}
    </StyledBox>
  </MuiModal>
}