import React from "react";
import {Button, Typography} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {DropdownModal} from "../../../../DropdownModal";
import {useClipboard} from "../../../../../hooks/useClipboard";
import {useTranslation} from "react-i18next";

export function CopyButton({close, link}: { close: () => void, link: string }) {
  const {t} = useTranslation();
  const clipboard = useClipboard();

  function onCopy() {
    clipboard.copy(link).then(() => close());
  }

  return <>
    <Button ref={clipboard.ref} variant="contained" size="small" onClick={onCopy}>
      <ContentCopyIcon style={{paddingRight: ".3em"}}/>
      {t("t:copy")}
    </Button>
    <DropdownModal
      sx={{p: ".8em"}}
      anchorEl={clipboard.ref.current}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      transformOrigin={{vertical: "top", horizontal: "right"}}
      open={clipboard.copied}
      onClose={close}>
      <Typography variant="body1" component="p">{t("t:copied")}</Typography>
    </DropdownModal>
  </>
}