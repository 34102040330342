import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {equals, map, reduce, reject} from "ramda";
import {ConfigurationContext} from "./useConfiguration";
import {ExtendedProduct, ProductContext} from "./useProduct";
import {
  determineProductInfos,
  getAssemblyPositions,
  getPartsFromProducts,
  toCartQueryParams,
  toPartInformation,
  toProductIds
} from "./partUtils";
import {useTranslation} from "react-i18next";
import {saveAs} from 'file-saver';
import {downloadPDF, sendEmail, sendToCart} from "../service/partsService";
import {ARCodeContext} from "./useARCode";
import {MailInputState} from "./useMail";
import {Part, PartDocuments, PartState} from "../types/parts";

export enum PARTS_DOWNLOAD {
  PDF,
}

export const initialDocuments: PartDocuments = {
  murr_doc_systemdocumentation: undefined,
  murr_doc_moduledocumentation: undefined,
  murr_doc_2D_CAD_drawing: undefined,
  murr_doc_step_model: undefined,
  murr_doc_certificates: undefined,
}

export const initialPart: Part = {
  image: "",
  text1: "",
  text2: "",
  id: "",
  articleNumber: "",
  assemblyPositions: [],
  quantity: 1,
  documents: initialDocuments
};

export const PartsContext = createContext<PartState>({
  id: undefined,
  parts: [],
  save: async function () {
    throw new Error("save not initialized")
  },
  send: async function () {
    throw new Error("send not initialized")
  },
  reload: async function () {
    throw new Error("reload not initialized")
  },
  download: async function () {
    throw new Error("download not initialized")
  },
  mail: async function () {
    throw new Error("mail not initialized")
  },
  toCart: async function () {
    throw new Error("toCart not initialized")
  },
  reset: function () {
    throw new Error("reset not initialized")
  },
  add: function () {
    throw new Error("add not initialized")
  },
  remove: function () {
    throw new Error("remove not initialized")
  }
});

export const PartsProvider = PartsContext.Provider;

function useParts(): PartState {
  const configuration = useContext(ConfigurationContext);
  const product = useContext(ProductContext);
  const arCode = useContext(ARCodeContext)
  const {i18n} = useTranslation();
  const [partsState, setParts] = useState<Part[]>([]);
  const [partId, setPartId] = useState<string | undefined>(undefined);

  const reload = useCallback(async function (id: string): Promise<void> {
    setPartId(id);
    const {parts} = await configuration.reload(id);
    const productIds = map(part => part.id, parts);
    const entries = await product.get(productIds);
    setParts(reduce(toPartInformation(entries), [], parts));
  }, [configuration, product, setParts]);

  const save = useCallback(async function (): Promise<Part[]> {
    if (partId) {
      setPartId(undefined);
    }
    const {state, bom} = await configuration.result();
    const entries = await product.get(reduce(toProductIds, [], bom));
    const positions = getAssemblyPositions(state);
    const newParts = getPartsFromProducts(entries, positions);
    setParts(newParts);
    return newParts
  }, [configuration, product, setParts, partsState]);

  const send = useCallback(async function (): Promise<string> {
    const partsTO = map(({id, assemblyPositions: assemblyPositions, quantity}) => ({id, assemblyPositions: assemblyPositions, quantity}), partsState);
    const _partId = await configuration.save({parts: partsTO});
    setPartId(_partId);
    return _partId;
  }, [save, partsState, configuration]);

  const download = useCallback(async function (what: PARTS_DOWNLOAD): Promise<string> {
    const id = await send();
    if (what == PARTS_DOWNLOAD.PDF) {
      const code = await arCode.generate();
      const blob = await downloadPDF({id, zippedImage: configuration.snapShot || [], qrCode: code}, i18n.language)
      const file = new File(
          [blob],
          `${id}-${product.name}.pdf`,
          {type: "application/pdf"}
      );
      saveAs(file);
      return id;
    } else {
      return id;
    }
  }, [partsState, arCode, product, i18n.language, send]);

  const mail = useCallback(async function (contactTO: MailInputState): Promise<string> {
    const id = await send();
    const code = await arCode.generate();
    await sendEmail({
      id,
      qrCode: code,
      contactTO,
      zippedImage: configuration.snapShot || [],
    }, i18n.language);
    return id;
  }, [arCode, partsState, i18n.language, send]);

  const toCart = useCallback(async function (): Promise<string> {
    const id = await send();
    // ME-86
    //await resetCart();
    const queryParam = reduce(toCartQueryParams, "", partsState);
    await sendToCart(queryParam);
    return id;
  }, [partsState, send]);

  const add = useCallback(async function (extendedProduct: ExtendedProduct) {
    setParts([...partsState, {
      ...initialPart,
      ...determineProductInfos(extendedProduct),
      id: extendedProduct.id
    }])
  }, [partsState, setParts]);

  const remove = useCallback(function (id: string) {
    setParts(reject((part: Part) => equals(part.id, id), partsState))
  }, [partsState, setParts]);

  useEffect(() => {
    if (configuration.visNode) {
      const productIds = map(({id}) => id, partsState);
      product.get(productIds)
        .then(products => setParts(reduce(toPartInformation(products), [], partsState)));
    }
  }, [i18n.language]);

  return {
    id: partId,
    parts: partsState,
    add,
    remove,
    save,
    send,
    reload,
    download,
    mail,
    toCart,
    reset: () => setPartId(undefined)
  };
}

export function PartStore({children}: { children: ReactNode }) {
  return <PartsProvider value={useParts()}>
    {children}
  </PartsProvider>
}
