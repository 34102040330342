import React, {ReactNode} from "react";
import {Button, ButtonProps} from "@mui/material";
import {SvgIconTypeMap} from "@mui/material/SvgIcon/SvgIcon";
import {OverridableComponent} from "@mui/material/OverridableComponent";

interface IconType extends OverridableComponent<SvgIconTypeMap> {
  muiName: string;
}

interface PartButtonProps extends ButtonProps {
  icon: IconType,
  children: ReactNode,
}

export function PartButton({icon, children, ...props}: PartButtonProps) {
  const Icon = icon;
  return <Button sx={{justifyContent: "flex-start", "&:not(:last-child)": {margin: 0}}} {...props}>
    <Icon style={{fontSize: "2rem", paddingRight: "0.6rem",}}/>
    {children}
  </Button>
}