import {Divider, Typography} from "@mui/material";
import {DownloadBox} from "./downloadBox/DownloadBox";
import React, {useContext, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {CheckBox} from "../../../components/CheckBox";
import {Flex} from "../../../components/helpers/Flex";
import {InfoButton} from "../../../components/InfoIcon";
import {PartsContext} from "../../../context/useParts";
import {useModal} from "../../../hooks/useModal";
import {DropdownModal} from "../../../components/DropdownModal";
import {AddToCart} from "./partButtons/AddToCart";
import {DownloadParts} from "./partButtons/DownloadParts";
import {any, map, propEq, split} from "ramda";
import {SaveConfiguration} from "./partButtons/saveConfiguration/SaveConfiguration";
import {ProductContext} from "../../../context/useProduct";
import {DownloadItemProductGroup} from "./downloadBox/DownloadItemProductGroup";

export function PartsSidebar() {
  const {add, remove, parts} = useContext(PartsContext);
  const {get} = useContext(ProductContext);
  const {isOpen, open, close, ref} = useModal();
  const {t} = useTranslation();
  const withAssembly = useMemo(() => any(propEq("id", "murr_montageposition"))(parts), [parts]);

  useEffect(() => {
    const lsWithAssembly = JSON.parse(window.localStorage.getItem("withAssembly") || "false");
    if (!withAssembly && lsWithAssembly) {
      get("murr_montageposition")
        .then(product => add(product["murr_montageposition"]));
    }
  }, [withAssembly]);

  async function onCheckedWithAssembly() {
    window.localStorage.setItem("withAssembly", JSON.stringify(!withAssembly));
    if (withAssembly) {
      return remove("murr_montageposition");
    }
    const product = await get("murr_montageposition");
    return add(product["murr_montageposition"]);
  }

  return <Flex flexDirection="column" sx={{marginTop: "20px"}}>
    <Flex alignItems="center">
      <CheckBox checked={withAssembly} onChange={onCheckedWithAssembly}/>
      <Typography display="flex" sx={{gap: "0.5rem", flex: 1}}>
        {t("t:with_assembly")}
        <Typography component="span" sx={{fontWeight: "bold", flex: 1, display: "flex", justifyContent: "center"}}/>
      </Typography>
      <InfoButton ref={ref} onClick={() => open()}>
        <InfoOutlinedIcon sx={{fontSize: "1.2rem"}}/>
      </InfoButton>
      <DropdownModal
        headline={t("t:with_assembly")}
        onClose={close}
        anchorEl={ref?.current}
        open={isOpen}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        transformOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        {map(item =>
                <Typography key={item} component="p" pt=".5em" variant="body1">{item}</Typography>,
            split("\n", t("t:with_assembly_text")))}
      </DropdownModal>
    </Flex>
    <Flex flexDirection="column" sx={{gap: "0.8rem", m: "1.75em 0 2.125em 0"}}>
      <AddToCart/>
      <DownloadParts/>
      <SaveConfiguration/>
    </Flex>
    <DownloadBox headline={t("t:cad_headline")}>
      <Divider sx={{my: "1rem"}}/>
      <Flex flexDirection="column" sx={{gap: "0.8rem"}}>
        <DownloadItemProductGroup groupId={"murr"} valueName={"murr_doc_all"} />
      </Flex>
    </DownloadBox>
  </Flex>
}