import {equals, keys, map} from "ramda";
import React, {Fragment} from "react";
import {Box, Button, Checkbox, Grid, TextField, Typography} from "@mui/material";
import {Flex} from "../../../../helpers/Flex";
import {useTranslation} from "react-i18next";
import {DropdownModal} from "../../../../DropdownModal";
import {VALIDATION_STATUS} from "../../../../../hooks/useValidate";
import {useMailForm} from "./useMailForm";

export function MailForm({saveClose, close}: { saveClose: () => void, close: () => void }) {
  const {t} = useTranslation();
  const {
    onCloseValidate,
    toggleValidate,
    toggleChecked,
    inputs,
    checkboxes,
    onChange,
    validate,
    error
  } = useMailForm(saveClose);

  return <>
    <Grid spacing={0} container>
      {map(key =>
          <Fragment key={key}>
            <Grid display="flex" alignItems="center" flex={1} xs={12} md={5} item>
              <Typography variant="body2" component="p" width="100%">
                {t(`t:form_${key}`)}
              </Typography>
            </Grid>
            <Grid xs={12} md={7} p="1em 0" item>
              <Box>
                <TextField
                  defaultValue={inputs[key]}
                  sx={{width: "100%"}}
                  variant="outlined"
                  onChange={onChange(key)}
                  onBlur={e => toggleValidate(key, e.target.value)}
                  error={equals(validate.validated[key].status, VALIDATION_STATUS.INVALID)}
                />
              </Box>
            </Grid>
          </Fragment>
        , keys(inputs))}
    </Grid>
    <Grid spacing={0} p="2em 0" container>
      {map(key =>
          <Fragment key={key}>
            <Grid xs={1} item>
              <Checkbox
                color={equals(validate.validated[key].status, VALIDATION_STATUS.INVALID) ? "error" : "primary"}
                checked={checkboxes[key]}
                onClick={() => toggleChecked(key, !checkboxes[key])}
              />
            </Grid>
            <Grid xs={11} sx={{p: "1em"}} item>
              <Typography component="p" variant="body2">
                <Typography
                  component="span"
                  onClick={() => toggleChecked(key, !checkboxes[key])}
                  sx={{cursor: "pointer", userSelect: "none"}}
                >
                  {t(`t:form_${key}`)}
                </Typography>
              </Typography>
            </Grid>
          </Fragment>
        , keys(checkboxes))}
    </Grid>
    <Flex justifyContent="flex-end" paddingTop="1em">
      <Button onClick={close} variant="contained">{t("t:cancel")}</Button>
      <Button ref={error.ref} onClick={() => onCloseValidate()} variant="contained">{t("t:continue")}</Button>
      <DropdownModal
        sx={{p: ".8em"}}
        anchorEl={error.ref?.current}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        transformOrigin={{vertical: "bottom", horizontal: "right"}}
        open={error.isOpen}
        onClose={error.close}>
        <Typography component="p" variant="body1">
          {t(error.message)}
        </Typography>
      </DropdownModal>
    </Flex>
  </>
}