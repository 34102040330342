import {Button, ButtonProps, styled} from "@mui/material";
import {ReactNode} from "react";

const StyledButton = styled(Button)(({theme}) => ({
  width: "228px",
  height: "55px",
  margin: 0,
  "&:not(:last-child)": {
    margin: "0 15px 0 0",
  },
  "&.MuiButton-outlined": {
    fontWeight: 600,
    borderBottom: "1px solid #FFF",
  },
}));

interface TabButtonProps extends ButtonProps {
  selected: boolean,
  children: ReactNode,
}

export function TabButton({selected, children, ...props}: TabButtonProps) {
  return <StyledButton
    variant={selected ? "outlined" : "contained"}
    {...props}
  >
    {children}
  </StyledButton>
}