import {DownloadItem} from "./DownloadItem";
import React, {useContext, useEffect, useState} from "react";
import {ProductGroupDocument, ProductGroupInformation} from "../../../../types/parts";
import {MediaValue, ProductGroupResult} from "@encoway/c-services-js-client";
import {ProductGroupContext} from "../../../../context/useProductGroup";

function toDocuments({valueName, result}: ProductGroupDocument) {
    const mediaValue = (result.productGroup.characteristicValues[valueName].values[0]) as MediaValue
    const name = result.characteristics[0].name as string;
    const format = result.characteristics[0].characteristicValues.FILENAME_EXTENSION.values[0] as string
    return <DownloadItem
        name={name}
        format={format}
        uri={mediaValue.uri}
        mediaType={mediaValue.mediaType}
        fileName={mediaValue.fileName}
    />
}

export function DownloadItemProductGroup({groupId, valueName}: ProductGroupInformation) {
    const {getProductGroup} = useContext(ProductGroupContext);
    const [productGroup, setProductGroup] = useState<ProductGroupResult>();
    useEffect(() => {
        getProductGroup(groupId).then(setProductGroup)
    }, [])

    if (!productGroup) {
        return <></>
    }
    return <>{toDocuments({valueName: valueName, result: productGroup})}</>
}