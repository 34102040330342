import {Checkbox, CheckboxProps, styled} from "@mui/material";
import React from "react";

const StyledCheckboxIcon = styled('span')(({theme}) => ({
  border: `2px solid ${theme.palette.info.contrastText}`,
  borderRadius: 3,
  width: 22,
  height: 22,
}));

const StyledCheckboxIconChecked = styled(StyledCheckboxIcon)(({theme}) => ({
  color: theme?.palette.primary.main,
  "&:before": {
    display: "block",
    backgroundSize: "cover",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23519E1A'/%3E%3C/svg%3E\")",
    content: '""',
    width: 18,
    height: 18
  },
}));

export const CheckBox = (props: CheckboxProps) => (
  <Checkbox icon={<StyledCheckboxIcon/>} checkedIcon={<StyledCheckboxIconChecked/>} {...props} />
)