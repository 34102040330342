import {CircularProgress, Container} from "@mui/material";
import React, {useEffect} from "react";
import {BusyOverlayProps} from "@encoway/cui-application-components";

function preventInput(event: any) {
  event.preventDefault()
  event.stopPropagation()
}

export function CustomBusyOverlay(props: BusyOverlayProps) {

  useEffect(() => {
    const cuiRoot = document.getElementById("cui")
    if (cuiRoot) {
      cuiRoot.addEventListener("keydown", preventInput)
      cuiRoot.addEventListener("keypress", preventInput)
      cuiRoot.addEventListener("keyup", preventInput)
      return () => {
        cuiRoot.removeEventListener("keydown", preventInput)
        cuiRoot.removeEventListener("keypress", preventInput)
        cuiRoot.removeEventListener("keyup", preventInput)
      }
    }
  }, [props])

  return (
    <Container sx={{
      position: "absolute",
      overflow: "hidden",
      top: 0,
      left: 0,
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `#FFFFFF80`,
      zIndex: 1000,
    }}>
      <CircularProgress/>
    </Container>
  )
}