import {CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import {AppStore} from "./context/useApp";
import {ProductStore} from "./context/useProduct";
import {PreConfigurationStore} from "./context/usePreConfiguration";
import {ConfigurationStore} from "./context/useConfiguration";
import {PartStore} from "./context/useParts";
import {MailStore} from "./context/useMail";
import React, {ReactNode} from "react";
import {theme} from "./theme";
import {ARCodeStore} from "./context/useARCode";
import {ProductGroupStore} from "./context/useProductGroup";

export function Provider({children}: { children: ReactNode }) {
    return <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AppStore>
                <ProductStore>
                    <ProductGroupStore>
                        <PreConfigurationStore>
                            <ConfigurationStore>
                                <MailStore>
                                    <ARCodeStore>
                                        <PartStore>
                                            {children}
                                        </PartStore>
                                    </ARCodeStore>
                                </MailStore>
                            </ConfigurationStore>
                        </PreConfigurationStore>
                    </ProductGroupStore>
                </ProductStore>
            </AppStore>
        </ThemeProvider>
    </StyledEngineProvider>
}