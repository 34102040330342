import {Flex} from "./helpers/Flex";
import {Button, styled, TextField, Typography} from "@mui/material";
import {Modal} from "./Modal";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useModal} from "../hooks/useModal";
import {ArrowForward} from "@mui/icons-material";
import {test} from "ramda";
import {DropdownModal} from "./DropdownModal";

const StyledTypography = styled(Typography)(({theme}) => ({
  cursor: "pointer",
  color: theme.palette.primary.main
}));

const StyledArrowForwardIcon = styled(ArrowForward)(({theme}) => ({
  color: theme.palette.primary.main,
  marginRight: ".25em"
}));

export function ForwardButton() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {isOpen, close, open} = useModal();
  const [input, setInput] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const button = useRef(null);

  useEffect(() => {
    setInput(window.localStorage.getItem("last_entered_configuration_id") || "");
  }, []);

  function onChangeInput(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setInput(e.currentTarget.value);
  }

  function navigateToConfiguration() {
    if (!test(/^([Vv][Xx])?\d{8}$/, input)) {
      return setError(true);
    }
    close();
    if (test(/^\d{8}$/, input)) {
      window.localStorage.setItem("last_entered_configuration_id", "VX" + input);
      return navigate(`/configuration/VX${input}`);
    }
    window.localStorage.setItem("last_entered_configuration_id", input);
    return navigate(`/configuration/${input}`);
  }

  return <>
    <Flex sx={{alignItems: "center"}}>
      <StyledArrowForwardIcon/>
      <StyledTypography sx={{cursor: "pointer"}} onClick={open}>{t("t:start_configuration_from_id")}</StyledTypography>
    </Flex>
    <Modal
      open={isOpen}
      close={close}
      title={t("t:start_configuration_from_id")}
    >
      <Typography sx={{marginBottom: ".25em"}}>{t("t:start_configuration_from_id_text")}</Typography>
      <Flex sx={{flex: 1, paddingTop: 2, width: "100%"}}>
        <TextField sx={{width: "100%"}} defaultValue={input} variant="outlined" onChange={onChangeInput} error={error}/>
      </Flex>
      <Flex sx={{justifyContent: "flex-end", margin: "2em 0 0 0"}}>
        <Button sx={{padding: ".5em 1em"}} variant="contained" onClick={close}>{t("t:cancel")}</Button>
        <Button sx={{padding: ".5em 1em"}} ref={button} variant="contained"
                onClick={navigateToConfiguration}>{t("t:ok")}</Button>
        <DropdownModal
          anchorEl={button.current}
          anchorOrigin={{vertical: "bottom", horizontal: "right"}}
          transformOrigin={{vertical: "top", horizontal: "right"}}
          open={error}
          onClose={() => setError(false)}>
          <Typography component="p" variant="body1">
            {t("t:start_configuration_from_id_wrong_input")}
          </Typography>
        </DropdownModal>
      </Flex>
    </Modal>
  </>
}