import React, {createContext, ReactNode, RefObject, useCallback, useEffect, useRef, useState} from "react";
import {Visualization as VisualEditor} from "@encoway/visual-editor";
import {SETTINGS} from "../settings";
import {loadBackground, loadVisualisation, saveVisualisation} from "../service/configurationService";
import {any, equals, length} from "ramda";
import {Visualization, VisualizationNode, VisualizationNodeState} from "../types/visualization";
import {PartsTO} from "../types/parts";
import {convertImage} from "./configurationUtils";

export interface ConfigurationState<T extends object> {
  save(additionalData: T, result?: VisualizationNode<{}>): Promise<string>,

  load(): Promise<Visualization>,

  reload(id: string): Promise<VisualizationNode<T>>,

  loadRequirements(state: object): Promise<Visualization>,

  reset(): Promise<void>,

  result(): Promise<VisualizationNode<{}>>

  createSnapshot(): Promise<number[] | undefined>,

  id: string | undefined,
  vis: Visualization | undefined,
  visRef: RefObject<unknown> | undefined,
  visNode: VisualizationNodeState<{}>,
  settings: any,
  edited: boolean,
  snapShot: number[] | undefined
}

export const ConfigurationContext = createContext<ConfigurationState<PartsTO>>({
  id: undefined,
  vis: undefined,
  visRef: undefined,
  visNode: undefined,
  settings: undefined,
  edited: false,
  snapShot: undefined,
  save: async function () {
    throw new Error("save function not initialized")
  },
  load: async function () {
    throw new Error("load function not initialized")
  },
  reload: async function () {
    throw new Error("reload function not initialized")
  },
  loadRequirements: async function () {
    throw new Error("loadRequirements function not initialized")
  },
  reset: async function () {
    throw new Error("reset function not initialized")
  },
  result: async function () {
    throw new Error("result function not initialized")
  },
  createSnapshot: function () {
    throw new Error("snapshot function not initialized")
  }
});

export const ConfigurationProvider = ConfigurationContext.Provider;

export function useConfiguration<T extends object>(): ConfigurationState<T> {
  const [vis, setVis] = useState<Visualization>();
  const [visNode, setVisNode] = useState<VisualizationNodeState<{}>>();
  const [snapShot, setSnapshot] = useState<number[] | undefined>();
  const [edited, setEdited] = useState<boolean>(false);
  const [settings, setSettings] = useState(SETTINGS.vc.settings);
  const visRef = useRef();

  /**
   * Loads the visualization once the configurationContext is started
   * @return Promise the visualization object
   */
  const load = useCallback(async function (rootGroup: string = "murr"): Promise<Visualization> {
    const {vc: {baseUrl, version, token}, server} = SETTINGS
    const _vis: Visualization = await VisualEditor.load(baseUrl, version, token)
    _vis.cloud.addServer({...server, options: {...server.options, rootGroup}});
    _vis.cloud.supervisor._maxCount = Infinity;
    setVis(_vis);
    return _vis
  }, [setVis]);

  /**
   * Loads the already existing configuration by its ID in the database. If a state already exists, the fetch is skipped.
   * @param id: the configurationID e.g. "VX10000025"
   * @return Promise|undefined returns Configuration or undefined if not present.
   */
  const reload = useCallback(async function (id: string): Promise<VisualizationNode<T>> {
    const _vis: Visualization = vis || await load();
    // reload from backend data
    const {data} = await loadVisualisation<T>(id);
    setSnapshot(data.snapshot);
    setVisNode({data, id});
    _vis.cloud.restore(data.state);
    if (edited) {
      setEdited(false);
    }
    return data;
  }, [vis, load, edited, setEdited]);

  /** Loads a configuration via script from product catalog.
   * In this case the parameters of the pre configuration (guiTO.rootContainer.children[0].parameters)
   * are used and their values are passed as {[parameter.name]: parameter.selectedValues[0].value}.
   * Thereby a script from studio is fetched and used as an visualization entrypoint to generate the nodes.
   * Should only be used if a pre configuration object exists and is completed (guiTo.rootContainer.readyState === "READY")
   * @param state: the state object to run the script with from pre configuration
   * @return Promise<Visualization> the modified vis object
   */
  const loadRequirements = useCallback(async function (state: any): Promise<Visualization> {
    // If vis is not already initialized, we load the vis again and set the rootGroup from preConfiguration
    const _vis: Visualization = vis || await load(state["murr_dynamic_root_group"]);
    // clear all nodes, to make sure nothing gets overwritten
    _vis.cloud.clear();
    // fill the state into the cloud to run the script with
    _vis.cloud.props.replaceState(state);
    // load the script, run with type "product" and mount it with the state object
    // e.g. pre configuration parameter selection values
    await _vis.cloud.mount(
      undefined,
      "product",
      undefined,
      {id: "V99_mitSkript"},
      "default",
      false,
      false
    );
    if (edited) {
      setEdited(false);
    }
    return _vis;
  }, [vis, edited, setEdited]);

  /**
   * Saves the currently held configuration and the associated visualization data.
   * @return Promise|undefined returns Configuration or undefined if no Configuration is present or already saved.
   */
  const save = useCallback(async function (additionalData: T, visuResult?: VisualizationNode<{}>): Promise<string> {
    if (vis) {
      // get new result or use param
      const visResult = await vis.result("flat");
      // check if bom is empty and return nothing
      if (equals(length(visResult.bom), 0)) {
        throw new Error("bom is empty");
      }
      // save new bom/vis and return saved id
      const data = {...visResult, ...additionalData};
      const savedVisualisation = await saveVisualisation<T>(data);
      setVisNode({data, id: savedVisualisation.data});
      return savedVisualisation.data
    }
    throw new Error("visualization is undefined");
  }, [vis, visNode, setVisNode]);

  const result = useCallback(async function (): Promise<VisualizationNode<{}>> {
    if (vis) {
      setEdited(false);
      return vis.result("flat");
    }
    throw new Error("The visualization was not rendered correctly, so no result can be generated.");
  }, [vis, setEdited, snapShot]);

  /**
   * Deletes the current state of the configuration
   */
  const reset = useCallback(async function () {
    setVisNode(undefined);
    if (vis) {
      await vis.cloud.clear();
      setVisNode(undefined);
    }
  }, [vis, setVisNode]);

  const createSnapshot = useCallback(async function () {
    if (visRef && visRef.current) {
      try {
        //@ts-ignore
        const image = await visRef.current.renderImages({
          width: 900,
          height: 600,
          orthographic: false,
          view: [{
            Isometric: "isometric",
            Fit: "fit",
            Top: "top",
            Bottom: "bottom",
            Front: "front",
            Rear: "rear",
            Back: "back",
            Left: "left",
            Right: "right",
            TopFront: "top-front",
            TopBack: "top-back"
          }]
        });
        const _image = [...await convertImage(image)];
        setSnapshot(_image);
        return _image;
      } catch (e) {
        return undefined;
      }
    }
    throw new Error("visualization is not rendered, can not generate snapshot");
  }, [visRef, setSnapshot]);

  useEffect(() => {
    loadBackground()
      .then(background => setSettings({...settings, ui: {...settings.ui, background}}));
  }, []);

  useEffect(() => {
    if (vis) {
      return vis.cloud.graph().eventBus().onValue(function (e: any) {
        const updateEvents = ["NodeRemoved", "loading"];
        if (!edited && any(event => equals(e.event, event), updateEvents)) {
          setEdited(true);
        }
      });
    }
  }, [vis, visRef, setEdited, edited]);

  return {
    id: visNode?.id,
    vis,
    visRef,
    visNode,
    snapShot,
    save,
    load,
    reload,
    loadRequirements,
    reset,
    result,
    createSnapshot,
    settings,
    edited,
  };
}

export function ConfigurationStore({children}: { children: ReactNode }) {
  return <ConfigurationProvider value={useConfiguration<PartsTO>()}>
    {children}
  </ConfigurationProvider>
}