import {Flex} from "../../../components/helpers/Flex";
import React, {useContext} from "react";
import {PreConfigurationContext} from "../../../context/usePreConfiguration";
import {PATH} from "../../../components/layout/tabs/useTabs";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {styled, Typography} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const StyledDeleteIcon = styled(DeleteOutlinedIcon)(({theme}) => ({
  color: theme.palette.primary.main,
  marginRight: ".25em"
}));

const StyledTypography = styled(Typography)(({theme}) => ({
  cursor: "pointer",
  color: theme.palette.primary.main
}));

export function ResetButton() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {start} = useContext(PreConfigurationContext);

  async function onReset() {
    const {cfg} = await start()
    navigate(`${PATH.PRE}${cfg.id()}`, {replace: true});
  }


  return <Flex sx={{justifyContent: "flex-end", flex: 1}}>
    <Flex onClick={onReset}>
      <StyledDeleteIcon/>
      <StyledTypography sx={{cursor: "pointer"}}>{t("t:empty_form")}</StyledTypography>
    </Flex>
  </Flex>
}