import React from "react";
import {Box, styled} from "@mui/material";
import {Tabs} from "./tabs/Tabs";
import {ButtonRow} from "./buttonRow/ButtonRow";
import {Outlet} from "react-router-dom";

export const LayoutWrapper = styled(Box)(({theme}) => ({
  "@media (min-width: 1400px)": {
    paddingRight: "348px",
    paddingLeft: "348px",
  },
}));

export function Layout({wrap = true, showButtonRow = true}: { wrap?: boolean, showButtonRow?: boolean }) {
  return <div id="encoway">
    <LayoutWrapper sx={{mt: "20px", display: "flex", flexFlow: "row"}}>
      <Tabs/>
      {showButtonRow && <ButtonRow/>}
    </LayoutWrapper>
    {wrap
      ? <LayoutWrapper sx={{p: "2.5em", minHeight: "min(400px, calc(100vh - 235px))"}}>
        <Outlet/>
      </LayoutWrapper>
      : <Outlet/>
    }
  </div>
}