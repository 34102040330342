import {styled} from "@mui/material";
import {Flex} from "../../helpers/Flex";
import {ShareButton} from "./shareButton/ShareButton";
import {ArButton} from "./arButton/ArButton";

const StyledFlex = styled(Flex)(() => ({
  flex: "1",
  justifyContent: "end",
  position: "relative",
  alignItems: "flex-end",
}));

export function ButtonRow() {
  return <StyledFlex>
    <ArButton/>
    <ShareButton/>
  </StyledFlex>
}