import {match, slice} from "ramda";
import {SETTINGS} from "../settings";

export function getLocation(): string {
  const isDefaultPort = (protocol: string, port: string) => ((protocol === "http:" && port === "")
    || (protocol === "https:" && port === "")
    || (protocol === "http:" && port === "80")
    || (protocol === "https:" && port === "443"))

  // eslint-disable-next-line no-restricted-globals
  if (window && window.location && window.location.hostname) {
    // eslint-disable-next-line no-restricted-globals
    const {protocol, port, hostname} = window.location;
    if (isDefaultPort(protocol, port)) {
      return `${protocol}//${hostname}`;
    }
    return `${protocol}//${hostname}:${port}`;
  }
  const [_protocol, _hostname] = match(/^((http[s]?):\/\/)?([a-zA-Z0-9-.]*)/, SETTINGS.server.embedded.baseUrl);
  return `${_protocol}${_hostname}`;
}

export function getFullLocation(): string {
  // eslint-disable-next-line no-restricted-globals
  if (window && window.location && window.location.href) {
    // eslint-disable-next-line no-restricted-globals
    const [location] = match(/[^#]+/, window.location.href);
    if (location) {
      return slice(0, -1, location);
    }
  }
  return SETTINGS.server.embedded.baseUrl;
}