import {Box, styled} from "@mui/material";

export const Flex = styled(Box)(({theme}) => ({
  display: "flex",
}));

export const FlexCentered = styled(Box)(({theme}) => ({
  display: "flex",
  alignSelf: "center",
  alignItems: "center",
  justifyContent: "center",
  justifySelf: "center",
}));