import {fetchGet, fetchPost} from "./axiosWrapper";
import {VisualizationNode} from "../types/visualization";
import {ProductResult} from "@encoway/c-services-js-client";
import {pathOr} from "ramda";

export function saveVisualisation<T extends object>(data: VisualizationNode<T>) {
  return fetchPost<string, VisualizationNode<T>>("configured-material/save")({data});
}

export function loadVisualisation<T extends object>(identifier: string) {
  return fetchGet<VisualizationNode<T>>(`configured-material/load/${identifier}`)()
}

export async function loadBackground(): Promise<string> {
  const res = await fetchGet<ProductResult>(`api/catalog/products/VISUALIZATION_DEFAULT_MATERIAL`)({}, {"Accept-Language": "en-US"});
  return pathOr("", ["data", "product", "characteristicValues", "background", "values", 0, "uri"], res);
}