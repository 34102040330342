import {InfoButtonProps} from "@encoway/cui-configurator-components";
import React from "react";
import {DropdownModal} from "../DropdownModal";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useModal} from "../../hooks/useModal";
import {Typography} from '@mui/material';
import {ParameterTO} from '@encoway/c-services-js-client';
import {InfoButton} from "../InfoIcon";
import {isEmpty, isNil} from 'ramda';

interface CustomInfoButtonProps extends InfoButtonProps {
  data: ParameterTO
}

export function CustomInfoButton(props: CustomInfoButtonProps) {
  const {isOpen, open, close, ref} = useModal();

  const isAvailable = props.isInfoAvailable || (() => !isNil(props.data.shortText) && !isEmpty(props.data.shortText))
  if (!isAvailable(props)) {
    return null
  }

  return <>
    <InfoButton ref={ref} onClick={() => open()} sx={{padding: 0}}>
      <InfoOutlinedIcon sx={{fontSize: "1.2rem"}}/>
    </InfoButton>
    <DropdownModal
      headline={props.data.translatedName}
      onClose={close}
      anchorEl={ref?.current}
      open={isOpen}
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      transformOrigin={{vertical: "bottom", horizontal: "right"}}
    >
      <Typography variant="body1" component="p" sx={{maxWidth: "650px"}}>
        {props.data.shortText}
      </Typography>
    </DropdownModal>
  </>
}