import {useLocation} from "react-router-dom";
import {useContext, useState} from "react";
import {AppContext} from "../../../../context/useApp";
import {PartsContext} from "../../../../context/useParts";
import {startsWith} from "ramda";
import {useModal} from "../../../../hooks/useModal";

interface ShareButtonStore {
  open(): Promise<void>,

  close(): void,

  link: string,
  isOpen: boolean
  error: boolean,
}

export function useShareButton(): ShareButtonStore {
  const {pathname} = useLocation();
  const {uri} = useContext(AppContext);
  const {send, save} = useContext(PartsContext);
  const {open, close, isOpen} = useModal();
  const [link, setLink] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  async function onOpen(): Promise<void> {
    try {
      setError(false);
      open();
      await save();
      const _id = await send();
      if (startsWith("/configuration", pathname)) {
        setLink(`${uri}/#/configuration/id/${_id}`);
      } else {
        setLink(`${uri}/#/parts/${_id}`);
      }
    } catch (e) {
      console.error(e);
      setError(true);
    }
  }

  return {
    open: onOpen,
    close,
    isOpen,
    link,
    error,
  }
}