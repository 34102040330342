import {LOCAL_STORAGE_CONFIGURATION, PreConfigurationContext} from "../../context/usePreConfiguration";
import {useContext, useEffect} from "react";
import {PATH} from "../../components/layout/tabs/useTabs";
import {Navigate} from "react-router-dom";
import {ProductContext} from "../../context/useProduct";
import {SETTINGS} from "../../settings";
import {PartsContext} from "../../context/useParts";


export function LoadRequirements() {
  const {configurationId, load, start} = useContext(PreConfigurationContext);
  const {name} = useContext(ProductContext);
  const {reset, id} = useContext(PartsContext);

  useEffect(() => {
    if (id) {
      reset();
    }
    const lsId = sessionStorage.getItem(LOCAL_STORAGE_CONFIGURATION);
    if (lsId) {
      load(lsId).catch(() => start());
    } else {
      start(SETTINGS.requirements.articleName).then();
    }
  }, [])

  if (configurationId && name) {
    return <Navigate to={`${PATH.PRE}${configurationId}`}/>
  }

  return null;
}