import {initialModalState, ModalStore, useModal} from "../hooks/useModal";
import React, {ChangeEvent, createContext, ReactNode, useCallback, useState} from "react";
import {ValidatedValues, ValidationOptions} from "../types/validation";
import {VALIDATION_STATUS, VALIDATION_TYPE} from "../hooks/useValidate";

export interface MailInputState {
  company: string,
  firstname: string,
  lastname: string,
  street: string,
  plz: string,
  city: string,
  country: string,
  recipientEmail: string,
  ccEmail: string,
}

export interface MailCheckboxState {
  acceptPrivacyConditions: boolean,
  acceptSaveData: boolean,
}

export type MailTypeState = MailInputState & MailCheckboxState

export interface MailState {
  inputs: MailInputState,
  checkboxes: MailCheckboxState,
  error: ModalStore,

  onChange(id: keyof MailInputState): (event: ChangeEvent<HTMLInputElement>) => void,

  toggle(id: keyof MailCheckboxState): () => void,

  toggle(id: keyof MailCheckboxState): () => void,
}

export interface MailPostData {
  id: string,
  zippedImage: any,
  qrCode: string,
  contactTO: MailInputState,
}

export const VALIDATION_OPTIONS: ValidationOptions = {
  regExMail: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
}

const initialInput: MailInputState = {
  company: "",
  firstname: "",
  lastname: "",
  street: "",
  plz: "",
  city: "",
  country: "",
  recipientEmail: "",
  ccEmail: "",
};

export const VALIDATION_FIELDS: ValidatedValues<MailTypeState> = {
  acceptPrivacyConditions: {
    status: VALIDATION_STATUS.NOT_VALIDATED,
    type: [VALIDATION_TYPE.CHECKED, VALIDATION_TYPE.REQUIRED]
  },
  acceptSaveData: {
    status: VALIDATION_STATUS.NOT_VALIDATED,
    type: [VALIDATION_TYPE.CHECKED, VALIDATION_TYPE.REQUIRED]
  },
  recipientEmail: {
    status: VALIDATION_STATUS.NOT_VALIDATED,
    type: [VALIDATION_TYPE.REGEX_EMAIL, VALIDATION_TYPE.REQUIRED]
  },
  ccEmail: {
    status: VALIDATION_STATUS.NOT_VALIDATED,
    type: [VALIDATION_TYPE.REGEX_EMAIL]
  },
  company: {
    status: VALIDATION_STATUS.NOT_VALIDATED,
    type: [VALIDATION_TYPE.REQUIRED]
  },
  firstname: {
    status: VALIDATION_STATUS.NOT_VALIDATED,
    type: [VALIDATION_TYPE.REQUIRED]
  },
  lastname: {
    status: VALIDATION_STATUS.NOT_VALIDATED,
    type: [VALIDATION_TYPE.REQUIRED]
  },
  street: {
    status: VALIDATION_STATUS.NOT_VALIDATED,
    type: []
  },
  plz: {
    status: VALIDATION_STATUS.NOT_VALIDATED,
    type: []
  },
  city: {
    status: VALIDATION_STATUS.NOT_VALIDATED,
    type: []
  },
  country: {
    status: VALIDATION_STATUS.NOT_VALIDATED,
    type: [],
  },
}

const initialCheckbox: MailCheckboxState = {
  acceptPrivacyConditions: false,
  acceptSaveData: false,
}

export const MailContext = createContext<MailState>({
  inputs: initialInput,
  checkboxes: initialCheckbox,
  error: initialModalState,
  onChange: function () {
    throw new Error("onChange on Store not initialized");
  },
  toggle: function () {
    throw new Error("toggle on Store not initialized");
  }
});

export const MailProvider = MailContext.Provider;

function useMail(): MailState {
  const [inputs, setInputs] = useState<MailInputState>(initialInput);
  const [checkboxes, setCheckboxes] = useState<MailCheckboxState>(initialCheckbox);
  const error = useModal();

  const onChange = useCallback(function (id: keyof MailInputState): (input: ChangeEvent<HTMLInputElement>) => void {
    return function (event) {
      return setInputs(prev => ({...prev, [id]: event.target.value}));
    }
  }, [setInputs]);

  const toggle = useCallback(function (id: keyof MailCheckboxState) {
    return function () {
      return setCheckboxes(prev => ({...prev, [id]: !prev[id]}));
    }
  }, [setCheckboxes]);

  return {
    inputs,
    checkboxes,
    error,
    onChange,
    toggle,
  }
}

export function MailStore({children}: { children: ReactNode }) {
  return <MailProvider value={useMail()}>
    {children}
  </MailProvider>
}
