import {Box, Button, Typography} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Flex} from "../../../components/helpers/Flex";
import {ForwardButton} from "../../../components/ForwardButton";
import {PreConfigurator} from "./PreConfigurator";
import {ResetButton} from "./ResetButton";
import {PreConfigurationContext} from "../../../context/usePreConfiguration";

export function PreConfiguration() {
  const {t} = useTranslation();
  const {configurationId} = useContext(PreConfigurationContext);

  return <Box>
    <Typography variant="h1" component="h1">
      {t("t:requirements")}
    </Typography>
    <Flex flexDirection="column" sx={{minHeight: "620px", overflow: "hidden"}}>
      <Flex sx={{
        paddingBottom: "1.5em",
        borderBottom: "1px solid #00000050"
      }}>
        <ForwardButton/>
        <ResetButton/>
      </Flex>
      <Flex sx={{flex: 1}}>
        <PreConfigurator/>
      </Flex>
      <Button
        size="large"
        variant="contained"
        href={`#/configuration/pre/${configurationId}`}
        sx={{justifyContent: "flex-end", alignSelf: "flex-end", margin: "1em 1.375em 0 0"}}
      >
        {t("t:navigate_configuration")}
        <ArrowForwardIosIcon sx={{fontSize: "1rem", paddingLeft: ".25em"}}/>
      </Button>
    </Flex>
  </Box>
}