import {createTheme} from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(81, 158, 26)',
      light: 'rgba(238, 249, 235, 1)',
      dark: 'rgb(104, 188, 47)',
      contrastText: '#FFF',
    },
    secondary: {
      main: 'rgb(9, 104, 23)',
      light: 'rgb(220, 243, 215)',
      dark: 'rgb(0, 77, 11)',
      contrastText: '#FFF',
    },
    info: {
      main: 'rgb(220, 243, 215)',
      light: 'rgb(238, 249, 235)',
      dark: 'rgb(224, 224, 224)',
      contrastText: 'rgb(108, 108, 108)',
    },
    contrast: {
      main: 'rgba(225, 225, 225, 1)',
      light: 'rgba(224, 224, 224, 1)',
      dark: 'rgba(108, 108, 108, 1)'
    }
  },
  typography: {
    fontFamily: ['PT Sans bold', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: "1.4rem",
      fontWeight: 600,
      padding: "1em 0",
    },
    h2: {
      fontSize: "1.2rem",
      fontWeight: 600,
      color: "#6c6c6c"
    },
    h3: {
      fontSize: "1rem",
    },
    h4: {
      fontSize: ".8rem",
    },
    body1: {
      color: "#6c6c6c"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.MuiCheckbox-colorError": {
            color: "#d32f2f"
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "initial",
          border: "1px solid transparent",
          borderRadius: "initial",
          size: {
            large: {
              padding: ".8em 1.5em",
            },
          },
          "&:not(:last-child)": {
            margin: "0 .25em 0 0",
          },
        },
        contained: {
          color: "#FFF",
          border: " 1px solid #004d0b",
          background: "linear-gradient(rgb(10, 106, 24) 0%, rgb(1, 79, 12) 100%)",
          "&:hover": {
            boxShadow: "initial",
          },
          "&.Mui-disabled": {
            background: "rgb(248,248,248)",
            border: "1px solid rgba(0,77,11,.25)"
          }
        },
        outlined: {
          color: "rgb(108, 108, 108)",
          border: "1px solid rgb(225, 225, 225)",
          background: "#FFF",
          "&:hover": {
            background: "#FFF",
            border: "1px solid rgb(225, 225, 225)",
          }
        }
      }
    }
  }
});