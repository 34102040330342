import {equals, filter, findIndex, fromPairs, gt, join, keys, last, map, pathOr, propEq, reduce, split, update} from "ramda";
import {ExtendedCharacteristic, ExtendedProduct, Products} from "./useProduct";
import {BomEntry, BomSubItem, EdgeEntry, NodeEntry, NodeSubEntryArgs, VisualizationSaveState} from "../types/visualization";
import {Part, PartDocuments, PartTO} from "../types/parts";
import {initialDocuments} from "./constants";
import {MediaValue} from "@encoway/c-services-js-client";

export interface AssemblyPosition {
  name: string,
  id: string,
  position: number,
  prefix: number
}

interface EdgeNode {
  id: string,
  node: NodeEntry,
  edge: EdgeEntry
}

/**
 * Reducer function to accumulate all product ids from the bom
 * @param acc the accumulator with every found product id
 * @param bom the actual bom to search the product ids fro
 */
export function toProductIds(acc: string[], bom: BomEntry): string[] {
  function toSubIds(subAcc: string[], subBom: BomSubItem): string[] {
    if (equals(subBom.type, "product")) {
      return [...subAcc, subBom.id];
    }
    if (subBom.children) {
      return reduce(toSubIds, [], subBom.children);
    }
    return subAcc;
  }

  /**
   * It may happen that different items, such as the blind cap, consist of different sub products.
   * Therefore, we need to check each product for sub products within the BOM.
   */
  if (bom.subitems) {
    const subIds = reduce(toSubIds, [], bom.subitems);
    return [...acc, ...subIds, bom.productId];
  }
  return [...acc, bom.productId];
}

/**
 * Helper function to get all documents from a specific product
 * @param characteristics the characteristics to match with
 * @returns PartDocuments all found documents or undefined
 */
function toDocumentURIs(characteristics: ExtendedCharacteristic) {
  return function (acc: PartDocuments, ele: keyof PartDocuments): PartDocuments {
    const characteristic = characteristics[ele];
    if (characteristic) {
      const mediaValue = characteristic.values[0] as MediaValue;
      return {
        ...acc,
        [ele]: {
          ...mediaValue,
          name: characteristic.name,
          format: last(split("/", mediaValue.mediaType))
        }
      }
    }
    return acc;
  }
}

/**
 * Helper function to return current product information
 * @param product the extended current product
 * @returns part partial part
 */
export function determineProductInfos(product: ExtendedProduct): Pick<Part, "articleNumber" | "image" | "text1" | "text2" | "documents"> {
  return {
    articleNumber: pathOr("", ["characteristicValues", 'artikelnummer', 'values', 0], product),
    image: pathOr("", ["characteristicValues", 'Product_image', 'values', 0, "uri"], product),
    text1: pathOr("", ["characteristicValues", "vertriebstext_1", 'values', '0'], product),
    text2: pathOr("", ["characteristicValues", 'vertriebstext_2', 'values', '0'], product),
    documents: reduce(toDocumentURIs(product.characteristicValues), initialDocuments, keys(initialDocuments)),
  }
}

// Prefix for the mounting position
const POSITION_PREFIX = "ABCDEFG";

/**
 * Assembles the parts list. The position is obtained from positions e.g. AssemblyPositions.
 * The quantity is incremented depending on whether the part is duplicated, instead of adding a new one.
 * @param products the products to merge from
 * @param positions the positions to merge from
 * @returns part[] the reduced parts list
 */
export function getPartsFromProducts(products: Products, positions: AssemblyPosition[]): Part[] {
  function partsWithPositions(productsWithParts: Products) {
    return function (acc: Part[], {prefix, position, name}: AssemblyPosition): Part[] {
      const foundIndex = findIndex(propEq("id", name))(acc);
      // Product found, so it's updated instead of added to increase amount by one
      if (gt(foundIndex, -1)) {
        const {assemblyPositions, quantity} = acc[foundIndex];
        return update(foundIndex,
          {
            ...acc[foundIndex],
            // If it has no position in the AssemblyPosition, it is a backplane and therefore has no positions.
            // Otherwise, the position is added here with the appropriate prefix (whether backplane A, B or C..)
            assemblyPositions: gt(position, 0) ? [...assemblyPositions, `${POSITION_PREFIX[prefix]}-${position}`] : [],
            quantity: quantity + 1
          }, acc)
      }
      const product = productsWithParts[name];
      // It's the first object in the list
      return [...acc, {
        ...determineProductInfos(product),
        id: pathOr("", ["id"], product),
        // The same logic, except that this is the first element,
        // so we can recreate the array instead of merging in the empty accumulator.
        assemblyPositions: gt(position, 0) ? [`${POSITION_PREFIX[prefix]}-${position}`] : [],
        quantity: 1,
      }];
    }
  }

  return reduce(partsWithPositions(products), [], positions);
}

/**
 * Changes the translation for the sales texts of the bill of materials (parts)
 * @param products the products to merge with parts
 * @returns part[] the reduced translated parts lists
 */
export function toPartInformation(products: Products) {
  return function (acc: Part[], part: PartTO): Part[] {
    return [...acc, {
      ...part,
      ...determineProductInfos(products[part.id])
    }]
  }
}

/**
 * Determines the current assembly positions from the visualization state
 * @param state the state of the current vis object, fetched with vis.result()
 * @returns AssemblyPosition[] then current positions as an 3D array
 */
export function getAssemblyPositions(state: VisualizationSaveState) {
  /**
   * Groups edges and nodes together by their Id.
   * If it's a rootNode, edge.to is used as id
   * @param nodes the nodeEntry
   */
  function mergeEdgesAndNodes(nodes: NodeEntry[]): (edge: EdgeEntry) => EdgeNode {
    const nodePairs = fromPairs(map((node) => [node.id, node], nodes));
    return function (edge) {
      // Get the current id to match with bom ids later
      // If type is hidden, its a child node, if not its a rootNode
      const id = equals(nodePairs[edge.to].type, "hidden") ? edge.from : edge.to
      return {id, node: nodePairs[id], edge}
    }
  }

  /**
   * Determines the assembly positions and adds a prefix for later use like "A-1", "A-2", "A-3", "B-1" etc..
   * @param accumulator the accumulator for the assembly positions
   * @param element edges and nodes of the visualization to determine positions
   */
  function assemblyPositions(accumulator: AssemblyPosition[], element: EdgeNode): AssemblyPosition[] {
    const {edge, node, id} = element;
    const lastElement = last(accumulator);
    const args = node.args as NodeSubEntryArgs
    // Checks if an element exists or the accumulator is initial empty
    if (lastElement) {
      // Destructures the last used element of the assembly positions.
      const {position, prefix} = lastElement;
      // Is a component of the backplane and needs to be determined.
      if (equals(edge.relation, "next")) {
        // The backplane has not changed and the position is incremented by one.
        // The property of the last element is used as prefix.
        return equals("vx_x_empty", args.id)
          ? [...accumulator, ...map((child) => ({
            id: child.id,
            name: child.id,
            position: position + 1,
            prefix: prefix
          }), node.subitems[0].children)]
          : [...accumulator, {id, name: args.id, position: position + 1, prefix: prefix}];

      }
      // It is a new backplane (not the first) and therefore gets the position 0 (not shown cuz backplane)
      // and the prefix is incremented by one.
      return [...accumulator, {id, name: args.id, position: 0, prefix: prefix + 1}];
    }
    // The first backplane is set as start.
    return [{id, name: args.id, position: 0, prefix: 0}];
  }

  /**
   * Sorts the edges in the right order.
   * @param edges the edges from the visualization
   */
  function sortEdges(edges: EdgeEntry[]): EdgeEntry[] {
    return reduce(
      (acc: EdgeEntry[], edge: EdgeEntry) => {
        //The element is a backplane.
        if (!equals(edge.relation, "next")) {
          return [edge]
        }
        //Find the next element of the last element in accumulator.
        return [...acc, ...filter((nextEdge: EdgeEntry) => {
          return (equals(last(acc)!.to, nextEdge.from))
        }, edges)]
      },
      [],
      edges
    )
  }

  // Groups both objects together by their Id.
  const edgesNodes = map(mergeEdgesAndNodes(state.nodes), sortEdges(state.edges));
  // Reduce all edges and nodes to the assembly position.
  return reduce(assemblyPositions, [], edgesNodes);
}

/**
 * Reducer function to create the cart query params
 * @param partialQuery: string accumulator for the uri
 * @param part the actual part to reduce to
 * @returns function(accumulator,element):string the actual reducer function
 */
export function toCartQueryParams(partialQuery: string, part: Part): string {
  return `${partialQuery}&a[${part.articleNumber}]=${part.quantity}&t[${part.articleNumber}]=${encodeURIComponent(join(" ", part.assemblyPositions))}`
}
