import React, {useContext} from "react";
import {
  ComponentName as ConfiguratorComponentName,
  registerDefaultConfiguratorComponents, TabsProps
} from "@encoway/cui-configurator-components";
import {ComponentFactory, Configurator as EncowayConfigurator} from "@encoway/react-configurator";
import {CustomBusyOverlay} from "../../../components/cui/CustomBusyOverlay";
import {CustomCUIRadioButton} from "../../../components/cui/CustomCUIRadioButton";
import {PreConfigurationContext} from "../../../context/usePreConfiguration";
import {createTheme, ThemeProvider} from "@fluentui/react"
import {CustomInfoButton} from '../../../components/cui/CustomInfoButton';


registerDefaultConfiguratorComponents()
  .override(ConfiguratorComponentName.Spinner, CustomBusyOverlay)
  .override(ConfiguratorComponentName.InfoButton, CustomInfoButton)
  .override(ConfiguratorComponentName.RadioButton, CustomCUIRadioButton)
  .override(ConfiguratorComponentName.Tabs, (props: TabsProps) => ComponentFactory.instanceOf(ConfiguratorComponentName.Section, {
    ...props,
    onGetLabel: () => ""
  }))
  .override(ConfiguratorComponentName.LinkedTree, () => null);

const theme = createTheme({
  palette: {
    themePrimary: '#096817',
    themeLighterAlt: '#f0f9f1',
    themeLighter: '#c5e7ca',
    themeLight: '#99d2a1',
    themeTertiary: '#4ba558',
    themeSecondary: '#187b27',
    themeDarkAlt: '#085e15',
    themeDark: '#074f12',
    themeDarker: '#053b0d',
  },
});

export function PreConfigurator() {
  const {cfg, eventBus, language} = useContext(PreConfigurationContext);
  return <div id="cui" style={{flex: 1, position: "relative"}}>
    <ThemeProvider theme={theme}>
      {cfg && <EncowayConfigurator
          lang={language}
          config={cfg}
          eventBus={eventBus}
          options={{
            dontDeleteOnIdChange: false,
            deleteOnUnmount: false,
            hideLinkedTree: true,
          }}
          styles={{
            section: {
              flex: 1,
              display: "flex",
              flexDirection: "row",
              overflow: "hidden"
            },
            main: {
              paddingBottom: "30px",
              flex: 1,
            },
            aside: {
              display: "flex",
              overflow: "auto"
            }
          }}
      />}
    </ThemeProvider>
  </div>
}