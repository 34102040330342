import {Box, Button, Container} from "@mui/material";
import {VisualEditor} from "@encoway/visual-editor";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {ConfigurationContext} from "../../context/useConfiguration";
import {useNavigate} from "react-router-dom";

export function Configuration() {
  const {t, i18n} = useTranslation();
  const {createSnapshot, vis, visRef, settings} = useContext(ConfigurationContext);
  const navigate = useNavigate();

  async function onNext() {
    await createSnapshot();
    navigate("/parts");
  }

  return <>
    <Container disableGutters maxWidth={false} sx={{position: 'relative'}}>
      <Box sx={{height: "calc(100vh - 265px)"}}>
        {vis && <VisualEditor
            ref={visRef}
            visualization={vis}
            settings={settings}
            style={{width: "100%", height: "100%", overflow: "hidden"}}
            locale={i18n.language}
            onReady={() => (visRef as any).current.zoom()}
        />}
      </Box>
      <Box sx={{position: "absolute", bottom: "13px", right: "160px"}}>
        <Button size="large" variant="contained" onClick={onNext}>
          {t("t:navigate_parts_list")}
          <ArrowForwardIosIcon sx={{fontSize: "1rem", paddingLeft: ".25em"}}/>
        </Button>
      </Box>
    </Container>
  </>
}