import {fetchGet} from "./axiosWrapper";
import {head, path} from "ramda";
import {Language, MediaValue, ProductResult} from "@encoway/c-services-js-client";
import {Translation} from "../context/useApp";

export async function fetchLanguages(): Promise<Language[]> {
  const res = await fetchGet<{ languages: Language[] }>(`api/catalog/languages`)({});
  return res.data.languages;
}

export async function fetchTranslation(language: string): Promise<Translation> {
  const res = await fetchGet<ProductResult>(`api/catalog/products/application_texts`)({}, {"Accept-Language": language});
  if (path(["data", "product", "characteristicValues", "translations", "values"], res)) {
    const languageResource = head(res.data.product.characteristicValues.translations.values as MediaValue[]);
    if (languageResource) {
      const translationRes = await fetchGet<{ [key: string]: string }>(languageResource.uri)({}, null, true);
      return translationRes.data;
    }
  }
  return undefined;
}