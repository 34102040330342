import {Modal} from "../../../../../../components/Modal";
import {Flex} from "../../../../../../components/helpers/Flex";
import {Button, InputAdornment, OutlinedInput, Typography} from "@mui/material";
import {isEmpty} from "ramda";
import React, {ChangeEventHandler} from "react";
import {useTranslation} from "react-i18next";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

interface SaveModalProps {
  isOpen: boolean,
  value: string,
  loggedIn: boolean,
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
  save: () => void,
  close: () => void,
  saveButtonDisabled: boolean
}

function TitleIcon({loggedIn}: { loggedIn: boolean }) {
  const {t} = useTranslation();

  if (loggedIn) {
    return <>
      <SaveOutlinedIcon style={{paddingRight: ".3em", width: "32px"}}/>
      <Typography component="span">{t("t:configuration_save")}</Typography>
    </>
  }
  return <>
    <SaveOutlinedIcon style={{paddingRight: ".3em", width: "32px"}}/>
    <Typography component="span">{t("t:shop_login_necessary")}</Typography>
  </>
}

function SaveInputAdornment({value}: { value: string }) {
  const {t} = useTranslation();

  if (isEmpty(value)) {
    return <InputAdornment position="end">{t("t:max_characters")}</InputAdornment>
  }
  return null
}

export function SaveModal({isOpen, value, loggedIn, handleChange, save, close, saveButtonDisabled}: SaveModalProps) {
  const {t} = useTranslation();

  return <Modal close={close} open={isOpen} title={<TitleIcon loggedIn={loggedIn}/>}>
    {loggedIn ? <div style={{width: "50em"}}>
        <Typography component="span">{t("t:configuration_name")}</Typography>
        <Flex justifyContent="flex-start" paddingTop="1em">
          <OutlinedInput value={value} onChange={handleChange}
                         endAdornment={<SaveInputAdornment value={value}/>} fullWidth/>
        </Flex>
        <Flex justifyContent="flex-end" paddingTop="1em">
          <Button disabled={saveButtonDisabled} onClick={save} variant={saveButtonDisabled ? "outlined" : "contained"}>{t("t:save")}</Button>
        </Flex>
      </div>
      : <>
        <Typography component="span">{t("t:shop_login_required")}</Typography>
        <Flex justifyContent="flex-end" paddingTop="1em">
          <Button onClick={close} variant="contained">{t("t:ok")}</Button>
        </Flex>
      </>
    }
  </Modal>
}