import {Box, Button, styled, Typography} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import {Trans, useTranslation} from "react-i18next";
import React, {useContext, useState} from "react";
import {useModal} from "../../../../hooks/useModal";
import {Flex} from "../../../../components/helpers/Flex";
import {Modal} from "../../../../components/Modal";
import {CheckBox} from "../../../../components/CheckBox";
import {PartDocument} from "../../../../types/parts";
import {AppContext} from "../../../../context/useApp";
import axios from "axios";
import {saveAs} from "file-saver";

const Name = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main
}));

const InfoText = styled(Typography)(({theme}) => ({
  color: theme.palette.contrast.dark,
  fontSize: "0.8rem",
  paddingTop: "0.2rem",
  paddingLeft: "0.3rem"
}));

const DownloadButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.main,
  padding: 0,
  minWidth: 0
}));

export function DownloadItem({name, format, uri, mediaType, fileName}: PartDocument) {
  const {t} = useTranslation();
  const {shopProperties: {legalNotice, privacyPolicy}} = useContext(AppContext);
  const {open, close, isOpen} = useModal();
  const [agreement, setAgreement] = useState(false);

  async function onDownload() {
    axios.get(uri, {responseType: 'blob'}).then(({data}) => {
      const file = new Blob([data], {type: mediaType});
      saveAs(file, fileName);
    });
    close();
  }

  return <Box display="flex">
    <Box display="flex" flexDirection="row" flexGrow={1}>
      <Name>{name}</Name>
      <InfoText>.{format}</InfoText>
    </Box>
    <Box>
      <DownloadButton onClick={open}>
        <DownloadIcon/>
      </DownloadButton>
    </Box>
    <Modal
      close={close}
      open={isOpen}
      title={<Typography component="span">{t("t:terms_website")}</Typography>}
    >
      <Typography variant="body2" component="p" sx={{p: "1em 0"}} onClick={() => setAgreement(!agreement)}>
        <CheckBox checked={agreement} onClick={() => setAgreement(!agreement)}/>
        <Trans
          i18nKey="t:agreement"
          values={{privacyPolicy: t("t:privacy_policy"), legalNotice: t("t:legal_notice")}}
          components={{
            href1: <a href={privacyPolicy} target="_blank" rel="noopener noreferrer"/>,
            href2: <a href={legalNotice} target="_blank" rel="noopener noreferrer"/>,
          }}
        />
      </Typography>
      <Flex justifyContent="flex-end" paddingTop="1em">
        <Button onClick={close} variant="contained">{t("t:cancel")}</Button>
        <Button onClick={onDownload} variant={!agreement ? "outlined" : "contained" } disabled={!agreement}>{t("t:download")}</Button>
      </Flex>
    </Modal>
  </Box>
}