import {fetchPost} from "./axiosWrapper";
import {SETTINGS} from "../settings";

interface ARUpload {
  link: string,
  qr: string
}

export async function generateQRCode(compressedGLB: ArrayBuffer, scaleFactor: number, placement: string, token: string) {
  const data = {
    scaleFactor: scaleFactor,
    placement: placement,
    retentionTime: SETTINGS.server.ar.retentionTime || 14,
    glb: Buffer.from(compressedGLB).toString("base64")
  };
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + token
  };

  const uploadResult = await fetchPost<ARUpload>(`${SETTINGS.server.ar.baseUrl}/upload/`)({data: data, withCredentials: true}, headers, true)
  if (uploadResult.data.qr) {
    return uploadResult.data.qr;
  }
  throw new Error("Could not generate ar-Code in arService");
}