import {useContext, useMemo} from "react";
import {
  MailCheckboxState,
  MailContext,
  MailTypeState,
  MailState,
  VALIDATION_FIELDS,
  VALIDATION_OPTIONS
} from "../../../../../context/useMail";
import {useValidate, VALIDATION_STATUS} from "../../../../../hooks/useValidate";
import {equals} from "ramda";
import {ValidatedValue, ValidateStore} from "../../../../../types/validation";
import {PartsContext} from "../../../../../context/useParts";

export interface MailFormStore extends MailState {
  validate: ValidateStore<MailTypeState>,

  onCloseValidate(): void,

  toggleValidate(key: keyof MailTypeState, value: string | boolean): ValidatedValue | undefined,

  toggleChecked(key: keyof MailCheckboxState, value: boolean): ValidatedValue | undefined
}

export function useMailForm(saveClose: () => void): MailFormStore {
  const mail = useContext(MailContext);
  const parts = useContext(PartsContext);
  const validateState = useMemo(() => ({...mail.inputs, ...mail.checkboxes}), [mail.inputs, mail.checkboxes]);
  const validate = useValidate<MailTypeState>(validateState, VALIDATION_FIELDS, VALIDATION_OPTIONS)

  function onCloseValidate() {
    if (validate.validateEvery()) {
      parts.mail(mail.inputs).then();
      saveClose()
    }
    mail.error.setMessage("t:share_email_wrong_input");
    return mail.error.open();
  }

  function toggleValidate(key: keyof MailTypeState, value: string | boolean): ValidatedValue | undefined {
    if (equals(validate.validated[key].status, VALIDATION_STATUS.NOT_VALIDATED)) {
      return undefined
    }
    return validate.validate(key, value);
  }

  function toggleChecked(key: keyof MailCheckboxState, value: boolean): ValidatedValue | undefined {
    mail.toggle(key)();
    return toggleValidate(key, value);
  }

  return {
    ...mail,
    validate,
    onCloseValidate,
    toggleValidate,
    toggleChecked,
  }
}