import {RadioButton, RadioButtonProps} from "@encoway/cui-configurator-components"

export function CustomCUIRadioButton(props: RadioButtonProps) {
  return <RadioButton
    {...props}
    styles={{
      choiceGroup: {
        ".ms-ChoiceFieldGroup-flexContainer": {
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline"
        }
      },
      option: {
        paddingRight: "1em"
      }
    }}
  />
}
