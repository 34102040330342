import {Button, ButtonProps, styled, Typography} from "@mui/material";
import {ReactElement, RefObject} from "react";

const StyledButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.main,
  textTransform: "none"
}));

const StyledButtonText = styled(Typography)(({theme}) => ({
  margin: "0 0 0 0.2rem"
}));

interface ActionBarButtonProps extends ButtonProps {
  anchorRef?: RefObject<HTMLButtonElement>
  icon?: ReactElement<SVGRectElement>
  text: string
  onClick?: () => void
}

export const ButtonRowButton = ({anchorRef, icon, text, onClick, ...props}: ActionBarButtonProps) => (
  <StyledButton size="large" ref={anchorRef} disableRipple onClick={onClick} {...props}>
    {icon}
    <StyledButtonText>{text}</StyledButtonText>
  </StyledButton>
)