import {CatalogService, Http, ProductGroupResult} from "@encoway/c-services-js-client";
import React, {createContext, ReactNode, useCallback, useMemo} from "react";
import {SETTINGS} from "../settings";
import {useTranslation} from "react-i18next";

const http = Http.Basic(SETTINGS.server.credentials.user, SETTINGS.server.credentials.password);

export interface ProductGroupState {
    getProductGroup(id: string | string[]): Promise<ProductGroupResult>
}

export const ProductGroupContext = createContext<ProductGroupState>({
    getProductGroup: async function () {
        throw new Error("ProductGroupContext not initialized");
    }
});
export const ProductGroupProvider = ProductGroupContext.Provider;

export function useProductGroup() {
    const {i18n} = useTranslation();
    const catalogService = useMemo(() =>
            new CatalogService(http, SETTINGS.server.baseUrl, i18n.language),
        [i18n.language]);

    const getProductGroup = useCallback(async function (id: string): Promise<ProductGroupResult> {
        return catalogService.group(id)
    }, [catalogService, i18n.language])

    return {
        getProductGroup
    }
}

export function ProductGroupStore({children}: { children: ReactNode }) {
    return <ProductGroupProvider value={useProductGroup()}>
        {children}
    </ProductGroupProvider>
}
