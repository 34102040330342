import {FlexCentered} from "./helpers/Flex";
import {LayoutWrapper} from "./layout/Layout";
import {BoxProps, CircularProgress} from "@mui/material";
import React from "react";

export function Progress(props: BoxProps) {
  return <FlexCentered sx={{minHeight: "70vh"}} {...props}>
    <LayoutWrapper>
      <FlexCentered paddingBottom="10vh">
        <CircularProgress/>
      </FlexCentered>
    </LayoutWrapper>
  </FlexCentered>
}