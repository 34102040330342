import {useCallback, useState} from "react";
import {curry, equals} from "ramda";

export enum PROGRESS {
  NOT_LOADED,
  LOADING,
  LOADED,
  ERROR,
}

interface ProgressStatus {
  notLoaded: boolean,
  loading: boolean,
  loaded: boolean,
  error: boolean,
}

interface ProgressStore extends ProgressStatus {
  set(progress: PROGRESS): void
}

export function useProgress(): ProgressStore {
  const [progress, setProgress] = useState<ProgressStatus>({
    notLoaded: true,
    loading: false,
    loaded: false,
    error: false,
  });

  const set = useCallback(function (value: PROGRESS) {
    const equalsValue = curry(equals)(value);
    setProgress({
      notLoaded: equalsValue(PROGRESS.NOT_LOADED),
      loading: equalsValue(PROGRESS.LOADING),
      loaded: equalsValue(PROGRESS.LOADED),
      error: equalsValue(PROGRESS.ERROR)
    });
  }, [setProgress]);

  return {
    ...progress,
    set
  }
}