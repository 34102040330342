import React from "react";
import {Modal} from "../../../../../../components/Modal";
import {Flex} from "../../../../../../components/helpers/Flex";
import {Button, Link, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {getLocation} from "../../../../../../context/appUtils";
import {SvgIconTypeMap} from "@mui/material/SvgIcon/SvgIcon";
import {OverridableComponent} from "@mui/material/OverridableComponent";

interface ResponseModalProps {
  isOpen: boolean,
  success: boolean,
  titleIcon: OverridableComponent<SvgIconTypeMap>,
  close: () => void
}

interface ResponseModalTitleProps {
  success: boolean,
  icon: OverridableComponent<SvgIconTypeMap>
}

function Title({success, icon}: ResponseModalTitleProps) {
  const {t} = useTranslation();
  const message = success ? t("t:save_successful") : t("t:save_error");
  const Icon = icon;
  return <>
    <Icon style={{paddingRight: ".3em", width: "32px"}}/>
    <Typography component="span">{message}</Typography>
  </>

}

export function ResponseModal({isOpen, success, close, titleIcon}: ResponseModalProps) {
  const {t} = useTranslation();
  const shopHost = getLocation();

  return <Modal close={close} open={isOpen} title={<Title success={success} icon={titleIcon}/>}>
    {success ? <div style={{width: "50em"}}>
        <Typography component="span">{t("t:save_success_message")}</Typography>
        <Flex justifyContent="flex-start" paddingTop="1em">
          <Link
            href={`${shopHost}/index.php?lang=0&cl=wd_account_configurator_config`}> {t("t:my_configurations")} </Link>
        </Flex>
        <Flex justifyContent="flex-end" paddingTop="1em">
          <Button onClick={close} variant="contained">{t("t:ok")}</Button>
        </Flex>
      </div>
      : <>
        <Typography component="span">{t("t:save_error_message")}</Typography>
        <Flex justifyContent="flex-end" paddingTop="1em">
          <Button onClick={close} variant="contained">{t("t:ok")}</Button>
        </Flex>
      </>
    }
  </Modal>
}