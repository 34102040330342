import {Box} from "@mui/material";
import {TabButton} from "./TabButton";
import React from "react";
import {useTranslation} from "react-i18next";
import {useTabs} from "./useTabs";

export function Tabs() {
  const {t} = useTranslation();
  const {nav, selected} = useTabs();

  return <Box>
    <TabButton selected={selected.requirements} onClick={nav.requirements}>
      {t("t:requirements")}
    </TabButton>
    <TabButton selected={selected.configuration} onClick={nav.configuration}>
      {t("t:configuration")}
    </TabButton>
    <TabButton selected={selected.parts} onClick={nav.parts}>
      {t("t:parts_list")}
    </TabButton>
  </Box>
}