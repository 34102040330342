import {equals, filter, head, length} from "ramda";
import {GraphNode, Visualization} from "../types/visualization";
import {SETTINGS} from "../settings";

function unHighlight(scene: THREE.Object3D) {
  scene.traverse(obj => {
    let mtl = obj.userData.highlight;
    if (!mtl) {
      return;
    }

    delete obj.userData.highlight;

    obj.traverse((item: any) => {
      if (item.type !== "Mesh") {
        return;
      }

      if (mtl.id !== item.material.id) {
        delete item.userData.origMaterial;
        return;
      }

      if (item.userData.origMaterial) {
        item.material = item.userData.origMaterial;
        delete item.userData.origMaterial;
      }
    });
  });
}


function prepareSceneForARExport(scene: THREE.Object3D, placement: string) {
  unHighlight(scene);
  const clonedScene = scene.clone();

  if (equals(placement, "wall")) {
    clonedScene.rotateX((90 * Math.PI) / 180);
  }

  return clonedScene;
}

/**
 * Exports the current scene from visualization and compresses it with Draco3D.
 * @param vis visualization instance
 * @param placement the placement of the objects in the scene: floor or wall
 * @returns Promise<ArrayBuffer> the compressed and exported object ready for AR conversion
 */
export async function exportSceneForAR(vis: Visualization, placement: string) {
  const cloud = vis.cloud;
  const scene = cloud.graph().scene();
  const preparedScene = prepareSceneForARExport(scene, placement);
  const threeDExporterInfo = SETTINGS.vc.extensions.find(extension => extension.name === "3d-exporter");

  try {
    const exporter = await vis
        .useExtension("3d-exporter", threeDExporterInfo?.version || "latest", cloud.token);
    const result = await exporter.exportScene("glb", preparedScene, {compress: true});
    return result.content;
  } catch (e) {
    console.error(e);
    throw new Error("Could not export the current scene");
  }
}

/**
 * Filters the root node from all nodes. In this case until there is only the backplane left
 * @param nodes the graph nodes array from the visualization object
 * @returns graphNode|undefined the head node e.g. the backplane or undefined if nothing is found inside the vis
 */
export function getRootNode(nodes: GraphNode[]): GraphNode | undefined {
  return head(filter(node => equals(length(node.incoming()), 0), nodes))
}
