import {equals, gt, isEmpty, isNil, length, lt, none, test} from "ramda";
import {ValidatedValue, ValidationOptions} from "../types/validation";
import {VALIDATION_STATUS, VALIDATION_TYPE} from "./useValidate";

export function validateRegExp(regEx: RegExp | undefined) {
  return function (value: string | boolean): VALIDATION_STATUS {
    if (isNil(regEx)) {
      throw new Error("regEx not set. Can not validate regex");
    }
    if (typeof value !== "string") {
      throw new Error("value is not a string, can't perform regEx test on " + value);
    }
    if (!test(regEx, value)) {
      return VALIDATION_STATUS.INVALID;
    }
    return VALIDATION_STATUS.VALID;
  }
}

export function validateChecked(value: string | boolean): VALIDATION_STATUS {
  if (typeof value !== "boolean") {
    throw new Error("value is not a boolean, can't perform checked test");
  }
  if (!value) {
    return VALIDATION_STATUS.INVALID
  }
  return VALIDATION_STATUS.VALID;
}

export function validateRequired(value: string | boolean): VALIDATION_STATUS {
  if (isEmpty(value)) {
    return VALIDATION_STATUS.INVALID
  }
  return VALIDATION_STATUS.VALID;
}

export function validateMinLength(minValue: number | undefined) {
  return function (value: string | boolean): VALIDATION_STATUS {
    if (isNil(minValue)) {
      throw new Error("minValue not set. Can not validate min length");
    }
    if (typeof value !== "string") {
      throw new Error("value is not a string, can't perform min value test on " + value);
    }
    if (lt(minValue, length(value))) {
      return VALIDATION_STATUS.INVALID
    }
    return VALIDATION_STATUS.VALID;
  }
}

export function validateMaxLength(maxValue: number | undefined) {
  return function (value: string | boolean): VALIDATION_STATUS {
    if (isNil(maxValue)) {
      throw new Error("maxValue not set. Can not validate max length");
    }
    if (typeof value !== "string") {
      throw new Error("value is not a string, can't perform max value test on " + value);
    }
    if (gt(maxValue, length(value))) {
      return VALIDATION_STATUS.INVALID
    }
    return VALIDATION_STATUS.VALID;
  }
}

export function validateTypes(options: ValidationOptions) {
  const validations = {
    [VALIDATION_TYPE.NONE]: () => VALIDATION_STATUS.VALID,
    [VALIDATION_TYPE.REQUIRED]: validateRequired,
    [VALIDATION_TYPE.CHECKED]: validateChecked,
    [VALIDATION_TYPE.REGEX_EMAIL]: validateRegExp(options.regExMail),
    [VALIDATION_TYPE.MIN_LENGTH]: validateMinLength(options.minValue),
    [VALIDATION_TYPE.MAX_LENGTH]: validateMaxLength(options.maxValue)
  }
  return function (value: string | boolean) {
    return function (acc: VALIDATION_TYPE[], element: VALIDATION_TYPE): VALIDATION_TYPE[] {
      if (equals(validations[element](value), VALIDATION_STATUS.VALID)) {
        return acc;
      }
      return [...acc, element];
    }
  }
}

export function validateValue(value: string | boolean, initialTypes: VALIDATION_TYPE[], type: VALIDATION_TYPE[]): ValidatedValue {
  if (length(type)) {
    if (typeof value === "string"
      && isEmpty(value)
      && none((validationType: VALIDATION_TYPE) => equals(validationType, VALIDATION_TYPE.REQUIRED))(initialTypes)) {
      return {status: VALIDATION_STATUS.VALID, type}
    }
    return {status: VALIDATION_STATUS.INVALID, type}
  }
  return {status: VALIDATION_STATUS.VALID, type}
}
