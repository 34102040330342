import {Dispatch, RefObject, SetStateAction, useRef, useState} from "react";

export interface ModalStore {
  ref: RefObject<any> | null
  isOpen: boolean,
  message: string,
  setMessage: Dispatch<SetStateAction<string>>,

  toggle(open?: boolean): void,

  close(): void,

  open(): void,
}

export const initialModalState: ModalStore = {
  ref: null,
  isOpen: false,
  message: "",
  setMessage: function () {
    throw new Error("setMessage in modal hook not defined");
  },
  toggle: function () {
    throw new Error("toggle in modal hook not defined");
  },
  close: function () {
    throw new Error("close in modal hook not defined");
  },
  open: function () {
    throw new Error("open in modal hook not defined");
  }
}

export function useModal(initialState = false): ModalStore {
  const ref = useRef(null);
  const [message, setMessage] = useState<string>("");
  const [isOpen, setOpen] = useState<boolean>(initialState);

  function toggle(open?: boolean) {
    if (open) {
      return setOpen(open);
    }
    return setOpen(prev => !prev);
  }

  return {
    ref,
    isOpen,
    message,
    setMessage,
    toggle,
    close: () => setOpen(false),
    open: () => setOpen(true)
  }
}